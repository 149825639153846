Luceo = window.Luceo || {};
Luceo.Components = Luceo.Components || {};

(function (Component) {

    var data,
        $top,
        $template;

    Component.load = function () {
        if ("jsProperties" in Luceo &&
            "header_data" in Luceo.jsProperties &&
            Luceo.jsProperties.header_data !== "null"
        ) {
            data = JSON.parse(Luceo.jsProperties.header_data);
            if (data['header_template'] && data['header_template'] != '') {
                $template = Luceo.Templates.get(data['header_template']);
            } else {
                $template = Luceo.Templates.get('components/header/main');
            }
            if (Object.keys(data).length > 0) {
                this.build();
            }
        }
    }

    Component.build = function () {

        var navigation = [];
        var tabs = getCurrentTab();

        data['config']['cdn_path'] = Luceo.jsProperties.cdn;

        /* select the correct tab in navigation */
        jQuery.each(data['navigation'], function (i, j) {
            if (tabs[i] && tabs[i]['selected']) data['navigation'][i]['selected'] = tabs[i]['selected'];
        })

        /* select the correct tab in subnavigation */
        jQuery.each(data['subnavigation'], function (i, j) {
            if (tabs[i] && tabs[i]['selected']) data['subnavigation'][i]['selected'] = tabs[i]['selected'];
        })

        data['navigation'] = buildNavigationArray(data['navigation']);
        data['subnavigation'] = buildNavigationArray(data['subnavigation']);
        data['services'] = [];

        /* For debugging */
        //data['user']['services'] = [];
        //data['user']['services'].push({name: 'Recruiter Desktop', url: 'http://employer.careerbuilder.com/jobposter/mycb.aspx'});
        //data['user']['services'].push({name: 'Talent Discovery', url: 'https://recruitment.careerbuilder.com/candidates'});
        //data['user']['services'].push({name: 'Test', url: 'http://www.google.fr'});

        /* Fake data */
        /*
        data['user']['services'] = [
            {
                account_id: "AKF7KX6RGNGT4DS2QCJ",
                account_level_access: false,
                account_status: "active",
                created: "2017-02-23T13:54:05.000Z",
                display_ui: true,
                end_date: "2019-02-21T04:59:59Z",
                id: "8a6eb54a-f9cf-11e6-9c3b-0e4f64eb6756",
                image_path: "https://secure.icbdr.com/images/TalentStream-Recruit-logo.png",
                last_modified: "2017-06-26T14:33:07.000Z",
                name: "Applicant Tracking",
                product_type: "ats",
                region: "us",
                start_date: "2017-02-21T05:00:00Z",
                status: "active",
                url: "https://kfix.luceosolutions.com",
                user_facing_name: "Applicant Tracking",
            }, {
                account_id: "AKF7KX6RGNGT4DS2QCJ",
                account_level_access: true,
                account_status: "active",
                created: "2017-08-04T16:11:41.000Z",
                display_ui: false,
                end_date: "2018-08-04T03:59:59Z",
                id: "9a66c9da-792f-11e7-9935-0ea50ba7bf70",
                last_modified: "2017-08-04T16:11:41.000Z",
                product_type: "recruitment",
                start_date: "2017-08-04T04:00:00Z",
                status: "active",
                url: "https://recruitment.careerbuilder.com/candidates",
                user_facing_name: "Talent Discovery"
            },
            {
                url: "https://connect.careerbuilder.com/s/",
                user_facing_name: "CB Connect"
            }
        ];
        */
        if ('ff' in data &&
            data.ff == 'AT-3501-UI-Parity-CBAT-HP'
        ) {
            data['cart_details'] = [];

            if ('user' in data &&
                'cart' in data.user &&
                'cart_details' in data.user.cart
            ) {
                for (var i = 0; i < data['user']['cart']['cart_details'].length; i++) {
                    var cart_details = data['user']['cart']['cart_details'][i];

                    cart_details['user_details'] = '';
                    var user_details = [];

                    //City
                    if (cart_details.city) {
                        user_details.push(cart_details.city);
                    }

                    //State Code
                    if (cart_details['current_state.code']) {
                        user_details.push(cart_details['current_state.code']);
                    }

                    var user_details_mobile = [];
                    if (user_details.join(', ')) {
                        user_details_mobile.push(user_details.join(', '));
                    }

                    //Mobile Phone
                    if (cart_details.mobile_phone) {
                        user_details_mobile.push(data['user']['cart']['mobile_no_label'] + ': ' + cart_details.mobile_phone);
                    }
                    cart_details['user_details'] = user_details_mobile.join(' | ');

                    data['cart_details'].push(cart_details);
                }
            }
        }

        if ('user' in data &&
            'services' in data.user) {
            for (var i = 0; i < data['user']['services'].length; i++) {
                var service = data['user']['services'][i];
                if ('user_facing_name' in service > 0 &&
                    'url' in service > 0) {
                    if (service.user_facing_name != 'Applicant Tracking') {
                        service.popup = true;
                    }
                    data['services'].push(service);
                }
            }

            for (x = 0; x < data['user']['services'].length; ++x) {
                if (data['user']['services'][x].user_facing_name == 'Applicant Tracking') data['user']['services'][x].isSelected = true;
            };

        }

        var $top = jQuery('#header .top').hide(),
            $topHeader = jQuery('<div class="top-header is-vdl" />').html(Mustache.render($template, data));

        $top.parent().append($topHeader);

        var $bellIcon = jQuery('#all-messages-list');
        $bellIcon.hide();

        if ("jsProperties" in Luceo && "header_data" in Luceo.jsProperties
            && "messenger" in Luceo.jsProperties && "setupHeader" in Luceo.jsProperties.messenger) {
            $bellIcon.show()
        }

        var $cartNumber = jQuery('#cart-number');
        var $messageNumber = jQuery('#message-number');

        if ('user' in data
            && 'cart' in data.user
            && 'number' in data.user.cart
            && data.user.cart.number > 0) {
            $cartNumber.show();
        } else {
            $cartNumber.hide();
        }

        if ('messenger' in data
            && 'unreadMessageCount' in data.messenger
            && data.messenger.unreadMessageCount > 0) {
            $messageNumber.show();
        } else {
            $messageNumber.hide();
        }

        bindDropDownMenus();
    }

    Component.toggleOldMenu = function () {
        getOldMenu().toggle();
    }

    Component.updateCartCounter = function (count) {
        jQuery('.is-cart span', $top).text(count);
        jQuery('.table_liste .cac input:checkbox').attr('checked', false);
    }

    function getOldMenu() {
        return jQuery('#header .top');
    }

    function getCurrentTab() {

        var array = {
            poste: { selected: false },
            candidate: { selected: false },
            correspondance: { selected: false },
            statistique: { selected: false },
            crm: { selected: false },
            referentiels: { selected: false },
            administration: { selected: false }
        };
        //Feature flag condn
        if ('ff' in data &&
            data.ff == 'AT-3501-UI-Parity-CBAT-HP'
        ) {
            array.home = { selected: false };
        }

        if ('new_menu_organization' in data && data.new_menu_organization) {
            array.job_posting = { selected: false };
        }

        var path = window.location.pathname.split('/');
        path.splice(0, 2);
        path = path.join('/');

        var patternPoste0 = /^poste/i,
            patternPoste1 = /^publication/i,
            patternPoste2 = /^prehome\/poste/i,

            patternCandidate0 = /^applicant/i,
            patternCandidate1 = /^candidat/i,
            patternCandidate2 = /^relance_lister/i,
            patternCandidate3 = /^relance_encours/i,
            patternCandidate4 = /^prehome\/candidat/i,

            patternCorrespondance0 = /^correspondance_file_attente/i,
            patternCorrespondance1 = /^prehome\/correspondance/i,

            patternStatistique0 = /^reporting/i,
            patternStatistique1 = /^requeteur/i,
            patternStatistique2 = /^statistique/i,
            patternStatistique3 = /^prehome\/reporting/i,

            patternCrm0 = /^client/i,
            patternCrm1 = /^prehome\/crm/i,

            patternReferentiels0 = /^ref/i,
            patternReferentiels1 = /^sq/i,
            patternReferentiels2 = /^prehome\/ref/i,

            patternAdministration0 = /^utilisateur/i,
            patternAdministration1 = /^administration_editer/i,
            patternAdministration2 = /^rdd/i,
            patternAdministration3 = /^toolindex/i,
            patternAdministration4 = /^toolstech/i,
            patternAdministration5 = /^prehome\/admin/i;

        if ('new_menu_organization' in data && data.new_menu_organization) {
            // job postings is a standalone menu
            if (patternPoste0.test(path) || patternPoste2.test(path)) {
                array.poste.selected = true;
                return array;
            }

            if (patternPoste1.test(path)) {
                array.job_posting.selected = true;
                return array;
            }
        } else {
            // job postings is part of requisitions menu
            if (patternPoste0.test(path) ||
                patternPoste1.test(path) ||
                patternPoste2.test(path)) {
                array.poste.selected = true;
                return array;
            }
        }

        if (patternCandidate0.test(path) ||
            patternCandidate1.test(path) ||
            patternCandidate2.test(path) ||
            patternCandidate3.test(path) ||
            patternCandidate4.test(path)) {
            array.candidate.selected = true;
            return array;
        }

        if (patternCorrespondance0.test(path) ||
            patternCorrespondance1.test(path)) {
            array.correspondance.selected = true;
            return array;
        }

        if (patternStatistique0.test(path) ||
            patternStatistique1.test(path) ||
            patternStatistique2.test(path) ||
            patternStatistique3.test(path)) {
            array.statistique.selected = true;
            return array;
        }

        if (patternCrm0.test(path) ||
            patternCrm1.test(path)) {
            array.crm.selected = true;
            return array;
        }

        if (patternReferentiels0.test(path) ||
            patternReferentiels1.test(path) ||
            patternReferentiels2.test(path)) {
            array.referentiels.selected = true;
            return array;
        }

        if (patternAdministration0.test(path) ||
            patternAdministration1.test(path) ||
            patternAdministration2.test(path) ||
            patternAdministration3.test(path) ||
            patternAdministration4.test(path) ||
            patternAdministration5.test(path)) {
            array.administration.selected = true;
            return array;
        }
        //Feature flag condn
        if ('ff' in data &&
            data.ff == 'AT-3501-UI-Parity-CBAT-HP'
        ) {
            var patternhome0 = /^desktop/i;
            if (patternhome0.test(path)) {
                array.home.selected = true;
                return array;
            }
        }

        return array;
    }

    function buildNavigationArray(navigation) {
        var array = [];

        /* Loop in JSON object */
        for (primary in navigation) {
            var currentPrimary = navigation[primary],
                secondaries = [];

            currentPrimary.id = primary

            /* secondary level */
            for (secondary in currentPrimary['actions']) {
                var currentSecondary = currentPrimary['actions'][secondary],
                    tertiaries = [];

                if (currentSecondary) {
                    currentSecondary.id = secondary;
                    secondaries.push(currentSecondary);
                }

                for (tertiary in currentSecondary['actions']) {
                    var currentTertiary = currentSecondary['actions'][tertiary];

                    if (currentTertiary) {
                        currentTertiary.id = tertiary;
                        tertiaries.push(currentTertiary);
                    }
                }
                currentSecondary.actions = tertiaries;
                currentSecondary.hasActions = (tertiaries.length) ? true : false;
            }
            currentPrimary.actions = secondaries;
            currentPrimary.hasActions = (secondaries.length) ? true : false;
            array.push(currentPrimary);
        }

        return array;
    }

    function reset() {
        /* Hide all submenus */
        jQuery(".show").removeClass("show");

        /* Reset user arrow */
        jQuery('.is-user .is-user-arrow')
            .addClass('fa-angle-down')
            .removeClass('fa-angle-up');

        /* Reset drop-menu state */
        jQuery(".is-drop-menu-active").removeClass("is-drop-menu-active");

        if ('ff' in data &&
            data.ff == 'AT-3501-UI-Parity-CBAT-HP'
        ) {
            jQuery('.border-box-yellow').removeClass('border-box-yellow');
        }
    }

    function bindDropDownMenus() {

        jQuery(document).click(function () {
            reset();
        });

        jQuery(".drop-menu-trigger-click").click(function (e) {

            if (!jQuery(this).next().hasClass("show")) {
                jQuery(".show").removeClass("show");
                //Feature flag condn
                if ('ff' in data &&
                    data.ff == 'AT-3501-UI-Parity-CBAT-HP'
                ) {
                    reset();
                    jQuery(this).parent()
                        .addClass('border-box-yellow');
                }

                jQuery(this).next()
                    .addClass("show");
                jQuery(this).addClass('is-drop-menu-active');

                if (jQuery(this).hasClass("is-user")) {
                    jQuery('.is-user-arrow', jQuery(this))
                        .removeClass('fa-angle-down')
                        .addClass('fa-angle-up');
                }
            } else {
                reset();
            }
            e.stopPropagation();
        });

        /* Menu opened via hover */
        jQuery(".drop-menu-trigger").hover(
            function (e) {
                var first = jQuery(this);
                second = first.next();

                first.addClass('is-drop-menu-trigger-selected');

                second
                    .addClass('show')
                    .hover(
                        function () {
                            first.addClass('is-drop-menu-trigger-selected');
                        },
                        function () {
                            first.removeClass('is-drop-menu-trigger-selected');
                        }
                    );
            },

            function (e) {
                var first = jQuery(this);
                second = first.next();

                jQuery(this).removeClass('is-drop-menu-trigger-selected');
                jQuery(this).next().removeClass('show');
            }
        );

        jQuery(".drop-menu-trigger").next().hover(function (e) {
            jQuery(this).addClass('show');
        }, function (e) {
            jQuery(this).removeClass('show');
            jQuery(this).prev().removeClass('is-drop-menu-trigger-selected');
        });

        jQuery(".tertiary-drop-menu-trigger").hover(function (e) {
            jQuery(this).next().addClass('tertiary-show');
        }, function (e) {
            jQuery(this).next().removeClass('tertiary-show');
        });

        jQuery(".tertiary-drop-menu-trigger").next().hover(function (e) {
            jQuery(this).addClass('tertiary-show');
        }, function (e) {
            jQuery(this).removeClass('tertiary-show');
        });

        jQuery(".drop-email-notification").click(function (e) {
            if (!jQuery(this).next().hasClass("show")) {
                jQuery(".show").removeClass("show");
                //Feature flag condn
                if ('ff' in data &&
                    data.ff == 'AT-3501-UI-Parity-CBAT-HP'
                ) {
                    reset();
                    jQuery(this).parent()
                        .addClass('border-box-yellow');
                }

                jQuery(this).next().addClass("show");
                jQuery(this).addClass('is-drop-menu-active');

                if (jQuery(this).hasClass("is-user")) {
                    jQuery('.is-user-arrow', jQuery(this))
                        .removeClass('fa-angle-down')
                        .addClass('fa-angle-up');
                }
            } else {
                reset();
            }
            e.stopPropagation();
        })
    }
})(Luceo.Components.Header = Luceo.Components.Header || {});

document.addEventListener("DOMContentLoaded", function () {
    Luceo.Components.Header.load();
});
