Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Form = Luceo.Views.Form || {};

(function($) {
    'use strict';
    Luceo.Views.Form.WorkterraPreview = function () {
        var $select = $(".wt-tour-select");

        $select.change(function () {
            // Converts WTFormXYZSelectOnboardingProcess into WTFormXYZTour
            var viewId = $(this).attr("id").replace("SelectOnboardingProcess", "Tour");
            var tourId = $(this).val();

            $("." + viewId).css("display", "none");

            if (tourId) {
                $("#" + viewId + tourId).toggle();
            }
        });
    };
}(jQuery));

(function (View) {
    View.onLoad = function () {
        Luceo.Views.Form.WorkterraPreview();
    };
})(Luceo.Views.Form.Hiringmultiple = Luceo.Views.Form.Hiringmultiple || {});
