Luceo = window.Luceo || {};

Luceo.Analytics = Luceo.Analytics || {};
(function(Analytics) {
    var CONSTS = {};
    CONSTS.GACustomVarsSlots = [];
    CONSTS.GACustomVarsSlots['userLogin'] = 1;
    CONSTS.GACustomVarsSlots['cbnetworkEnabled'] = 2;
    CONSTS.GACustomVarsSlots['jdnEnabled'] = 3;
    CONSTS.GACustomVarsSlots['system'] = 4;

    // Retrieve index slot for custom variable
    Analytics.getGACustomVarsSlot = function(index) {
        if (typeof CONSTS.GACustomVarsSlots[index] !== 'undefined') {
            return CONSTS.GACustomVarsSlots[index];
        } else {
            return null;
        }        
    };

    Analytics.trackGAEvents = function(params) {
        if (params.value == undefined) {
            params.value = '';
        }
        _gaq.push(['_trackEvent', params.category, params.label, params.value]);
    }

}(Luceo.Analytics = Luceo.Analytics || {}));

// Check if google analytics is enabled
if ('jsProperties' in Luceo
    && 'ga' in Luceo.jsProperties
    && 'enabled' in Luceo.jsProperties.ga
    && Luceo.jsProperties.ga.enabled == true
    ) {
    var _gaq = _gaq || [];
    _gaq.push(['_setAccount', Luceo.jsProperties.ga.id]);
    
    if ('analyticsData' in Luceo.jsProperties) {
        jQuery.each(Luceo.jsProperties.analyticsData, function(key, value) {
            if (Luceo.Analytics.getGACustomVarsSlot(key)) {
                _gaq.push(['_setCustomVar', Luceo.Analytics.getGACustomVarsSlot(key), key, value.toString(), 2]);
            }
        });
    }

    _gaq.push(['_trackPageview']);

    (function() {
        var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
        ga.src = 'https://ssl.google-analytics.com/ga.js';
    
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
    })();

    // Track changes on inputs
    var selectors = [
        'input[type="checkbox"]',
        'input[type="radio"]',
        'select'
        ];

    jQuery(document)
        .on('change',  selectors.join(), function() {
            var params = {
                'category': window.location.pathname,
                'label': jQuery(this).attr('name'),
                'value': jQuery(this).val()
            };
            Luceo.Analytics.trackGAEvents(params);
        });

    // Track changes on textareas
    var selectors = [
        'input[type="text"]',
        'textarea'
        ];

    jQuery(document)
        .on('change',  selectors.join(), function() {
            var params = {
                'category': window.location.pathname,
                'label': jQuery(this).attr('name')
            };
            Luceo.Analytics.trackGAEvents(params);
        });

    // Track click on buttons
    selectors = [
        'input[type="button"]',
        'button'
        ];

    jQuery(document)
        .on('click', selectors.join(), function() {
            var params = {
                'category': window.location.pathname,
                'label': jQuery(this).attr('name'),
                'value': jQuery(this).val()
            };
            Luceo.Analytics.trackGAEvents(params);
        });

    // Track click on links
    var selectors = [
        '.js-trk-links-click a'
        ];

    jQuery(document)
        .on('click', selectors.join(), function() {
            var params = {
                'category': window.location.pathname,
                'label': 'Link click',
                'value': jQuery(this).text()
            };
            Luceo.Analytics.trackGAEvents(params);
        });
}