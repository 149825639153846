Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Requisitions = Luceo.Views.Requisitions || {};

(function($) {
    function getReqState() {
        var name = '';
        var reqListStatus = $('[name="radEtat"]:checked').val();
        switch(reqListStatus) {
            case '0':
                name = 'All';
                break;
            case '1':
                name = 'Awaiting Approval';
                break;
            case '2':
                name = 'Open';
                break;
            case '3':
                name = 'Filled';
                break;
            case '4':
                name = 'Closed';
                break;
            case '5':
                name = 'Cancelled';
                break;
            case '6':
                name = 'Abandoned';
                break;
            default:
                name = 'Value for ' + reqListStatus + ' is not defined';
        }

        Luceo.GaShared.logEvent({
            event: 'requistionListStatus',
            requisitionStateNumber: reqListStatus,
            requisitionListStateFilter: name
        });
    }
    Luceo.Views.Requisitions.getReqState = getReqState;
}(jQuery));

(function (View) {
    View.onLoad = function () {
        Luceo.Views.Requisitions.getReqState();
        View.combineApprovalSates();
        View.exportList();
    };

    var $approvalRadioWrapper,
        $lastStateApprovalWrapper,
        $radEtat,
        $radDernierEtatValidation;

    View.combineApprovalSates = function() {
        if ("jsProperties" in Luceo &&
        ! ("at-894-req-approval" in Luceo.jsProperties)
        ) {
            return false;
        }

        /* Get radios elements */
        $radEtat = jQuery('input[name=radEtat]');
        $radDernierEtatValidation = jQuery('input[name=radDernierEtatValidation]');

        /* Hide main block */
        $radEtat.closest('.radio-list').css('visibility', 'hidden');

        /* Get wrappers */
        $approvalRadioWrapper = $radEtat.filter('[value=1]').parent();
        $lastStateApprovalWrapper = jQuery('.radDernierEtatValidation-wrapper');

        $radEtat.filter('[value=1]').css('visibility', 'hidden');

        /* Move sub radio block in radio block */
        $approvalRadioWrapper.append($lastStateApprovalWrapper);

        $radDernierEtatValidation.click(function() {
            $radEtat.filter('[value=1]').prop('checked', true);
            ta('radDernierEtatValidation');
        });

        $radEtat.closest('.radio-list').css('visibility', 'visible');
    }

    View.updateCountSteps = function(values) {
        jQuery.each(values, function(i, j) {
            $radio = $radEtat.filter('[value='+i+']').parent();
            jQuery('>label', $radio).append(' ('+j+')');
        })
    }

    View.updateCountStepsPending = function(values) {
        jQuery.each(values, function(i, j) {
            $radio = $radDernierEtatValidation.filter('[value='+i+']').parent();
            jQuery('>label', $radio).append(' ('+j+')');
        })
    }

    View.exportList = function() {
        $exportList = jQuery('#export-list');
        $exportList.bspopover({
            placement: 'left',
            trigger: 'manual',
            html: true,
            title: ''
        });

        $exportList.click(function(e) {
            e.preventDefault();

            jQuery.ajax({
                url: '/' + window._psfc + '.php/frontgateway/data-exporter/list',
                method: 'POST',
                dataType: 'json',
                data: atob($exportList.attr('data-params'))
            })
            .done(function() {
                $exportList.toggleClass('active');
                View.exportList.Showup($exportList, jQuery('.data-export-list-confirm.ok').html())
            })
            .fail(function() {
                View.exportList.Showup($exportList, jQuery('.data-export-list-confirm.ko').html())
            });
        });
    }

    // Shows up the given popover el with given HTML content
    View.exportList.Showup = function($el, content) {
        $el.data('bs.popover').options.content = content;
        $el.bspopover('show');
        setTimeout(function () {
            $el.bspopover('hide');
        }, 6000);
    }

})(Luceo.Views.Requisitions.Liste = Luceo.Views.Requisitions.Liste || {});
