Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Candidates = Luceo.Views.Candidates || {};

(function($) {
    function dateFilter() {
        dateFormat = Luceo.jsProperties.dateFormat;
        if (dateFormat) {
            dateFormat = dateFormat.replace('d', 'dd');
            dateFormat = dateFormat.replace('m', 'mm');
            dateFormat = dateFormat.replace('Y', 'yy');
        }

        $.fn.removeBtnClicked = function() {
            var buttonClicked = $("#vw").find(".buttonClicked");

            if (buttonClicked) {
                buttonClicked.removeClass("buttonClicked");
            }
        };

        $("#vwDateFilterdateRange").datepicker({
            numberOfMonths: 2,
            minDate: '-3y',
            maxDate: '+0d',
            dateFormat: dateFormat,
            onSelect: function( selectedDate ) {
                $(this).removeBtnClicked();

                if (!$(this).data().datepicker.first) {
                    $(this).data().datepicker.inline = true
                    $(this).data().datepicker.first = selectedDate;
                } else {
                    var dateStart = new Date($(this).data().datepicker.first);
                    var dateEnd = new Date(selectedDate);

                    if (Date.parse(dateStart) < Date.parse(dateEnd)) {
                        $(this).val($(this).data().datepicker.first+" - "+selectedDate);
                    } else {
                        if (Date.parse(dateStart) < Date.parse(dateEnd)) {
                            $(this).val($(this).data().datepicker.first+" - "+selectedDate);
                        } else {
                            $(this).val(selectedDate+" - "+$(this).data().datepicker.first);
                        }
                    }
                    $(this).data().datepicker.inline = false;
                }
            },
            onClose:function() {
                delete $(this).data().datepicker.first;
                $(this).data().datepicker.inline = false;
            }
        });

        $('#clearDate').on('click', function () {
            $('#vwDateFilterdateRange').datepicker().datepicker('setDate', '');
            $(this).removeBtnClicked();
        });
    }
    Luceo.Views.Candidates.dateFilter = dateFilter;
}(jQuery));
(function (View) {
    View.onCall = function () {
        Luceo.Views.Candidates.dateFilter();
    };
})(Luceo.Views.Candidates.Datefilter = Luceo.Views.Candidates.Datefilter || {});
