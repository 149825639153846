Luceo = window.Luceo || {};
Luceo.BlockLayout = Luceo.BlockLayout || {};

(function ($) {

    function trackAllWidgets() {
        $("div[data-ga-rr-widget]").each(function (i) {
            var id = $(this).attr('data-ga-rr-widget');
            var title = $(this).attr('data-ga-rr-widget-title');
            Luceo.GaShared.logEvent({
                event: 'RRWidgetImpression',
                widgetName: id,
                widgetDisplayedTitle: title
            })
        });
    }

    function trackClickedWidgets() {
        $("div[data-ga-rr-widget]").each(function (i) {
            $(this).on('click', function () {
                var id = $(this).attr('data-ga-rr-widget');
                var title = $(this).attr('data-ga-rr-widget-title');
                Luceo.GaShared.logEvent({
                    event: 'RRWidgetClick',
                    widgetName: id,
                    widgetDisplayedTitle: title
                })
            });
        });
    }


    Luceo.BlockLayout.trackAllWidgets = trackAllWidgets;
    Luceo.BlockLayout.trackClickedWidgets = trackClickedWidgets;
})(jQuery);

jQuery(document).ready(function(){
    Luceo.BlockLayout.trackAllWidgets();
    Luceo.BlockLayout.trackClickedWidgets();
})
