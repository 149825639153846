Luceo = window.Luceo || {};

(function ($) {
    $.fn.input = function (p) {

        $.each($('input[type=text], input[type=password], textarea'), function (i, e) {
            var element = $(e);
            var composant = new Input(element);
            return this;
        });

    };

    var Input = function (element) {

        if (element === undefined) throw new Error("Input: expecting 1 element.");

        // If element has value.
        if (element.val()) {
            element.parents('.input').addClass('focused');
        }
        /* Action on the main input */
        element.focusin(function () {
            $(this).parents('.input').addClass('focused');
        })
        .focusout(function () {
            if ( $(this).val() == "" && $(this).parents('.tbl_l-bit').siblings('li.tbl_l-bit-box-deletable').length < 1 ) {
                $(this).parents('.input').removeClass('focused');
            }
        });
    }

})(jQuery);

jQuery(document).ready(function () {
    jQuery('input[type=text],input[type=password]').input();
    jQuery(document).on('click', 'input[type=text],input[type=password], textarea', function () {
        jQuery(this).input();
        jQuery(this).trigger("focusin");
    });
    if (Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
        jQuery.each(jQuery('textarea'), function (i, e) {
            var element = jQuery(e);
            if (element[0].textLength > 0) {
                element[0].style.height = '5.2rem';
            }
        });
    }
});
