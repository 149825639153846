(function($)
{
    $.fn.inputHelper = function(p)
    {

        $.each($('input[data-type=input-helper]'), function (i, e) {
            var element = $(e);
            var composant = new InputHelper(element);
            return this;
        });

    };

    var parseGeoInput = function(geo, within, units) {
        var param = [];
        var splitParams = geo.split(within);

        if (splitParams.length === 2) {
            param['location'] = splitParams[0].trim();
            param['radius'] = splitParams[1].split(units)[0].trim();
        } else {
            param['location'] = geo;
            param['radius'] = 25;
        }

        return param;
    };

    var createQuery = function(motsCles, geoSearch, maxRadius, displayRadius) {
        return  encodeURIComponent(motsCles)
                + '&txtGeoSearch='
                + encodeURIComponent(geoSearch)
                + '&txtMaxRadius='
                + encodeURIComponent(maxRadius)
                + '&txtDisplayRadius='
                + encodeURIComponent(displayRadius)
    };

    var InputHelper = function(element){

        if(element === undefined) throw new Error("InputHelper: expecting 1 element.");

        var self = this;
        var inputFocus = false;
        var contentHover = false;
        var txtGeoSearch = $('#topSearchBartxtGeoSearch');
        var within = txtGeoSearch.data('translation-within');
        var units = txtGeoSearch.data('translation-units');

        var kmTranslation = {
            '10': '6',
            '25': '15',
            '50': '31',
            '75': '46',
            '100': '62',
        };

        var parent = $(element).parent();
        parent.addClass('input-helper')


        /* Wrap main input with div.js-is-searchable */
        element
            .attr('autocomplete', 'off');


        $('input[data-type=input-helper], input[data-type=input-geo-helper]').
            wrapAll('<div class="input-helper-wrapper" />');

        var wrapper = $('.input-helper-wrapper', parent);

        /* Generate content div */
        var content = $('<div />')
            .addClass('content')
            .hide();

        if(element.data('content-html')){
            content.html(Base64.decode(element.data('content-html')));
            content.addClass('content-html')
        }

        /* Backup link texts for reset when necessary */
        $('.action-links a', content).each(function(){
            $(this).data('text', $('span', this).text());
        })

        /* Change routes on the fly */
        $('.js-search-route', content)
            .each(function(i, j){
                $(j).data('base-url', $(j).attr('href'));
            })
            .click(function(e){
                var parsed = null;
                var displayRadius = null;
                if (txtGeoSearch.val()) {
                    parsed = parseGeoInput(txtGeoSearch.val(), within, units);
                    displayRadius = parsed['radius'];
                    parsed['radius'] = units === 'miles' ? parsed['radius'] : kmTranslation[parsed['radius']];
                    $(this).attr('href', $(this).data('base-url')
                        + createQuery(
                           element.val(),
                           parsed['location'],
                           parsed['radius'],
                           displayRadius
                        )
                    );
                } else {
                    $(this).attr('href', $(this).data('base-url') + encodeURIComponent(element.val()));
                }
        });


        /* Search in listings */
        $('.is-search-in a', content).each(function(){
            var parent = $(this).parent();
            var searchTranslation = parent.data('search-translation');
            var locationTranslation = $(this).data('search-location');
            var html =  '<span class="keyword">' + searchTranslation + '</span> <span class="highlight">' + locationTranslation + '</span>';
            if( html ) {
                $('>span', this).html(html);
            }
        })


        /* Wrapper properly content */
        wrapper.append(content);

        /* Load search template */
        $('.js-search-templates a').each(function(i, j) {
            var id = $(this).data('id-search');
            if( id ) {
                $(this).attr('href', '/bo.php/candidat/liste/recherche' + '?iIDRecherche=' + encodeURIComponent(id));
            }
        });

        /* Action on the main input */
        element
            .focusin(function(){
                inputFocus = true;

                // Do not display the input helper if we also display the OFCCP context
                Luceo = window.Luceo || {};
                if ('Ofccp' in Luceo && Luceo.Ofccp.shouldDisplayPopup()) {
                    return
                }

                showHelper();
            })
            .focusout(function(){
                inputFocus = false;
                    showHelper();
            })
            .keyup( function(){
                var value = $(this).val();

                /* Search in .is-searchable blocks -- last reviewed item + saved searches */
                $('.is-searchable a', content).each(function(){
                    var text = highlight($("span.title" ,this).text(), value);
                    if( text ) {
                        $('span', this).html(text);
                        $(this).show();
                    }
                    else{
                        $(this).hide();
                    }
                })

                $('.is-search-in a', content).each(function(){
                    var text = "'" + element.val() + "'";
                    var searchTranslation = $('.action-links.is-search-in.fluid')
                      .data('search-translation');

                    if ( element.val() === '' ) {
                        $('.keyword', $(this)).html(searchTranslation);
                    } else if ( element.val().length < 20 ) {
                        $('.keyword', $(this)).html(text);
                    }
                })
            })

        $('#txtMotsCles, #txtGeoSearch').keyup(function(){
            var title = [];

            if($('#txtMotsCles').val() != '') title.push($('#txtMotsCles').val());
            if($('#txtGeoSearch').val() != '') title.push($('#txtGeoSearch').val());

            $('#txtLibelle').val(title.join(', '));
        })

        content.hover(
            function() {
                contentHover = true;
                showHelper();
            },
            function() {
                contentHover = false;
                showHelper();
            }
        );

        function showHelper(){
            if(!inputFocus && !contentHover){
                content.hide();
                parent.removeClass('active');
            } else {
                content.show();
                parent.addClass('active');
            }
        }

        function preg_quote( str ) {
            // http://kevin.vanzonneveld.net
            // +   original by: booeyOH
            // +   improved by: Ates Goral (http://magnetiq.com)
            // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
            // +   bugfixed by: Onno Marsman
            // *     example 1: preg_quote("$40");
            // *     returns 1: '\$40'
            // *     example 2: preg_quote("*RRRING* Hello?");
            // *     returns 2: '\*RRRING\* Hello\?'
            // *     example 3: preg_quote("\\.+*?[^]$(){}=!<>|:");
            // *     returns 3: '\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:'

            return (str+'').replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, "\\$1");
        }

        function highlight( data, search ) {
            var newText = data.replace( new RegExp( "(" + preg_quote( search ) + ")" , 'gi' ), "<strong>$1</strong>" );
            return ( data == newText ) ? null : newText;
        }
    };


})(jQuery);

jQuery(document).ready(function () {
    jQuery('input[data-type=input-helper]').inputHelper();
});
