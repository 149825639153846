Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Candidates = Luceo.Views.Candidates || {};
Luceo.Views.Candidates.Dms = Luceo.Views.Candidates.Dms || {};
Luceo.Views.Candidates.Dms.Confirm = Luceo.Views.Candidates.Dms.Confirm || {};

(function (Confirm, $) {
    function showAlert() {
        if (jQuery('#showPrompt').val() == 'yes') {
            if (confirm(PsLocal.TXT_CONFIRM_SEND_DOC)) {
                jQuery(this).saveForm();
            }
        } else {
            jQuery(this).saveForm();
        }
    }

    jQuery.fn.saveForm = function() {
        PsAjax.submitView({
            controler: 'bo',
            id: 'wdwOfferletter',
            viewName: 'popup/offerletter_form.php',
            url: 'popup/offerletter_form',
            layout: 'windowlayout',
            action: 'btnSendDoc'
        }, {});
    };

    Confirm.showAlert = showAlert;
})(Luceo.Views.Candidates.Dms.Confirm, jQuery);
