Luceo = window.Luceo || {};

(function (GaShared) {

    GaShared.isDev = function() {
        return Luceo.jsProperties &&
        Luceo.jsProperties.env &&
        Luceo.jsProperties.env.dev;
    };

    GaShared.logEvent = function(data) {
        if (typeof dataLayer !== 'undefined') {
            if (GaShared.isDev()) {
                console.log(data);
            }
            dataLayer.push(data);
        }
    };

    GaShared.eachAttribute = function(obj, callback) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                callback(key, obj[key]);
            }
        }
    };

    GaShared.undefinedEmptyOrValue = function(searchFieldValue) {
        if (typeof searchFieldValue === 'undefined') {
            return 'unavailable';
        }
        if (searchFieldValue === '') {
            return 'empty'
        } else {
            return searchFieldValue;
        }
    };

    GaShared.allCandidateFoldersChecked = function(checkFieldsObj) {
        var checkedFolders = 0;
        var totalFolders = 0;
        Luceo.GaShared.eachAttribute(checkFieldsObj, function (key, value) {
            totalFolders++;
            if (jQuery(value).is(':checked')) {
                checkedFolders++;
            }
        });
        return checkedFolders == totalFolders;
    };

}(Luceo.GaShared = Luceo.GaShared || {}));
