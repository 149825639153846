Luceo.Views = Luceo.Views || {};
Luceo.Views.Jobpostings = Luceo.Views.Jobpostings || {};
Luceo.Views.Jobpostings.Confirm = Luceo.Views.Jobpostings.Confirm || {};

(function (Confirm, $) {
    function resendJobsToTN() {
        if (confirm(PsLocal.TXT_SECOND_COMFIRM_MESSAGE)) {
            jQuery.ajax({
                url: '/' + window._psfc + '.php/frontgateway/jobs/resend-to-tn',
                method: 'POST',
                dataType: 'json',
                data: JSON.stringify({ userId: jQuery('#wdwresendAllJobPostingsbtnOK').attr('data-userid'), jobSource: jQuery('#wdwresendAllJobPostingshidJobSource').val() }),
            }).done(function () {
                alert(PsLocal.TXT_RESEND_JOBS_TN_PROCESSING);
            }).fail(function() {
                alert(PsLocal.TXT_RESEND_JOBS_TN_ERROR);
            });
        }
    }

    Confirm.resendJobsToTN = resendJobsToTN;
})(Luceo.Views.Jobpostings.Confirm, jQuery);
