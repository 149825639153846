Luceo = window.Luceo || {};

Luceo.HtmlEditor = function(params) {

    var options;
    var TOOLBARS = {
        small: [
            'bold italic underline bullist numlist fontselect fontsizeselect forecolor backcolor | alignleft aligncenter alignright alignjustify | table'
        ],
        medium: [
            'bold italic underline strikethrough fontsizeselect | alignleft aligncenter alignright alignjustify',
            'outdent indent | undo redo | print | preview | forecolor backcolor | table'
        ],
        full: [
            'bold italic underline strikethrough fontsizeselect | alignleft aligncenter alignright alignjustify | table',
            'outdent indent | undo redo | print | preview | forecolor backcolor | searchreplace | bullist numlist | hr | link'
        ],
        frontofficecompliant: [
            'bold italic underline forecolor backcolor fontsizeselect | alignleft aligncenter alignright alignjustify outdent indent | table | styleselect',
            'link | hr bullist numlist | blockquote subscript, superscript | undo redo | preview searchreplace'
        ],
        customfrontofficecompliant: [
            'bold italic underline forecolor backcolor fontsizeselect | alignleft aligncenter alignright alignjustify outdent indent | table | styleselect',
            'link | hr bullist numlist | blockquote subscript, superscript | insertfile undo redo | preview searchreplace | image'
        ],
        email: [
            'bold italic underline strikethrough fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | table',
            'outdent indent image | insertfile undo redo | print | preview | forecolor backcolor | searchreplace | bullist numlist hr | link | image'
        ],
        compensationdetails: ['']
    };

    init();

    function init() {

        countChars(params.selector + '_remaining', jQuery(params.selector).val().length);

        options = {
            autoresize_min_height: 150,
            autoresize_max_height: 600,
            autoresize_bottom_margin: 10,
            extended_valid_elements : 'b/strong',
            formats : {
                bold : {
                    inline: 'b'
                },
                underline : {
                    inline: 'u', 
                    exact: true
                },
            },
            menubar : false,
            mode : 'exact',
            plugins : [
                'code table link hr advlist lists image textcolor charmap print preview anchor',
                'searchreplace visualblocks  fullscreen paste',
                'insertdatetime media autoresize moxiemanager'
            ],
            paste_preprocess: function(plugin, args) {
                args.content = args.content.replace(/<img[^>]*>/g , PsLocal.TXT_IMAGE_PASTE_MESSAGE);
            },
            init_instance_callback: function (editor) {
                editor.on('Change', function (e) {
                    countChars(params.selector + '_remaining', editor.getContent().length);
                });
            },
            relative_urls : false,
            remove_script_host : false,
            resize : 'both',
            browser_spellcheck : true,
            selector : 'textarea',
            toolbar : [
                'bold italic underline | alignleft aligncenter alignright alignjustify outdent indent | table | styleselect',
                'link | hr bullist numlist | blockquote subscript, superscript | insertfile undo redo | preview searchreplace | image'
            ],
            width : '100%'
        };

        run(params);
    }


    function run(params) {
        if(params.type && params.type in TOOLBARS){
            options.toolbar = TOOLBARS[params.type];
        }

        if(params.selector){
            options.selector = params.selector;
        }

        if(params.sa){
            var lastLine = options.toolbar.pop();
            options.toolbar.push(lastLine + ' | code');
        }

        /* if Jobs.net is activated, we must remove the image plugin*/
        if ("jsProperties" in Luceo &&
            "jobsdotnet_enable" in Luceo.jsProperties &&
            Luceo.jsProperties.jobsdotnet_enable)
        {
            for(var i=0; i<options.plugins.length; i++) {
                options.plugins[i] = options.plugins[i].replace('image', '');
            }

            for(var i=0; i<options.toolbar.length; i++) {
                options.toolbar[i] = options.toolbar[i].replace('image', '');
            }
        }


        tinymce.init(options);

        jQuery(options.selector).attr('data-htmleditor', 'tinymce');

        return true;
    }

    function countChars(selector, length) {
        jQuery(selector).text(length);
    }
}
