Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Candidates = Luceo.Views.Candidates || {};

(function (View) {
    'use strict';
    View.onLoad = function () {
        jQuery('.dms').click(function () {
            jQuery(this).find('span').text(function (_, value) { return value == '-' ? '+' : '-' });
            jQuery(this).nextUntil('tr.dms').slideToggle(100);
        });

        var root = document.querySelector('#dms_history');
        var selectAll = root.querySelector('#vw1dmsSelectAll');
        var checkboxes = root.querySelectorAll('.checkbox');

        // Select / Unselect checkboxes
        selectAll.addEventListener('click', function (e) {
            const checked = (selectAll.value == PsLocal.TXT_SELECT_ALL);

            checkboxes.forEach(function(doc){ doc.checked = checked; });
            selectAll.value = checked ? PsLocal.TXT_UNSELECT_ALL : PsLocal.TXT_SELECT_ALL;
        });

        // On check / uncheck checkboxes, verifying if we need to update the button label
        root.querySelectorAll('.checkbox').forEach(function(doc) {
            doc.addEventListener('change', function (e) {
                if (root.querySelectorAll('.checkbox:checked').length == root.querySelectorAll('.checkbox').length) {
                    root.querySelector('#vw1dmsSelectAll').value = PsLocal.TXT_UNSELECT_ALL;
                } else {
                    root.querySelector('#vw1dmsSelectAll').value = PsLocal.TXT_SELECT_ALL;
                }
            });
        });

        // Getting all documents ids that needs to be printed
        root.querySelector('#vw1dmsPrint').addEventListener('click', function (e) {
            var candidateId = root.querySelector('#print').getAttribute('data-value')
            var selectedDocuments = [];

            // Getting all checkboxes selected
            root.querySelectorAll('.checkbox:checked').forEach(function(doc) {
                selectedDocuments.push(doc.value);
            });

            // If no document selected nothing to do
            if (selectedDocuments.length === 0) {
                alert(PsLocal.TXT_SELECT_ONE_DOCUMENT);
                return;
            }

            window.open('dms_print_docs?candidateId=' + candidateId + '&documents=' + selectedDocuments.join(','), 'PrintDocs', '');
        });
        
    }
})(Luceo.Views.Candidates.History = Luceo.Views.Candidates.History || {});
