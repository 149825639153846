Luceo = window.Luceo || {};
(function(Iframe) {

    Iframe.resize = function(params) {
        if (params !== null 
            && typeof params === 'object'
            && 'iframe' in params
            && 'height' in params) {
            var margin = 60;
            document.getElementById(params.iframe).height = parseInt(params.height)+margin;
        }
    };

    Iframe.scroll = function(params) {
        if (params !== null 
            && typeof params === 'object'
            && 'position' in params) {
            jQuery("html, body").animate({ scrollTop: 0 }, "slow");
        }
    }

}(Luceo.Iframe = Luceo.Iframe || {}));