jQuery(document).ready(function (){

    var elements  = jQuery('#navigation > li li')
    jQuery.each(elements, function(i, element){
        if (jQuery(element).has('ul').length){
            jQuery('>a' , element).append('<i class="fa fa-chevron-right fa-1"></i>');
        }    
    });

});
