Luceo = window.Luceo || {};
Luceo.jsProperties = window.Luceo.jsProperties || {};

(function ($) {
    if (Luceo.jsProperties.upliftedUI) {
        $(document).ready(function() {
            var url = window.location.href;

            $('.widget-card').click(function() {
                $('.widget-card').removeClass('active');
                $(this).addClass('active');
            });
            if (url.indexOf('/desktop') !== -1) {
                $('.widget-card:nth-child(1)').addClass('active');
            } else if (url.indexOf('/poste') !== -1) {
                $('.widget-card:nth-child(2)').addClass('active');
            } else if (url.indexOf('/applicant') !== -1 || url.indexOf('/candidat') !== -1) {
                $('.widget-card:nth-child(3)').addClass('active');
            } else if (mactchReportingWidget(url) !== -1) {
                $('.widget-card:nth-child(4)').addClass('active');
            } else {
                $('.widget-card').removeClass('active');
            }
        });

        function mactchReportingWidget(url) {
            var reportingPattern = [
                '/reporting',
                '/requeteur',
                '/statistique',
                '/prehome/reporting'
            ];
            var reportUrlIndex = -1;
            $.each(reportingPattern, function(i, pattern) {
                if (url.indexOf(pattern) !== -1) {
                    reportUrlIndex = url.indexOf(pattern);
                    return false;
                }
            });
            return reportUrlIndex;
        }
    }
})(jQuery);
