Luceo = window.Luceo || {};
Luceo.Candidates = Luceo.Candidates || {};
(function (Search) {

    var txtFields = {
        searchKeywords: '#txtMotsCles',
        semanticGeo: '#txtGeoSearch',
        candidateProfileCity: '#txtVille',
        candidateProfileZip: '#txtCodePostal',
        candidateProfileLastName: '#txtNom',
        candidateProfileEmail: '#txtMail',
        candidateProfileFirstName: '#txtPrenom'
    };

    var dateFields = {
        candidateFoldersCandidateCreationDateStart: '#dtCreationDebut',
        candidateFoldersCandidateCreationDateEnd: '#dtCreationFin',
        candidateLastModifiedStartDate: '#dtDerniereModificationDebut',
        candidateLastModifiedEndDate: '#dtDerniereModificationFin',
        candidateApplicationRecievedBeginDate: '#dtCandidatureDebut',
        candidateApplicationRecievedEndDate: '#dtCandidatureFin'
    };

    var tableFields = {
        jobDesiredCountry: '#lstPaysRef_tbl',
        jobDesiredState: '#lstRegion_tbl',
        jobDesiredCity: '#lstDepartementGeo_tbl',
        jobDesiredZip: '#lstVille_tbl',
        jobDesiredDepartment: '#lstFiliere_tbl',
        jobDesiredJob: '#lstMetier_tbl',
        jobDesiredEmploymentType: '#lstTypeContrat_tbl',
        candidateProfileCountry: '#lstPays_tbl',
        candidateProfileTraining: '#lstFormationComplementaire_tbl',
        workHistoryWorkExperience: '#lstNiveauExperience_tbl',
        workHistoryEducationLevel: '#lstNiveauEtudes_tbl',
        workHistoryAvailability: '#lstDispo_tbl',
        assignmentsRequisition: '#lstPoste_tbl',
        assignmentsDispositionStatus: '#lstDernierEtat_tbl',
        assignmentsHighestStatus: '#lstEtatMax_tbl',
        assignmentsDispositioningReasons: '#lstMotifRefus_tbl',
        assignmentsHasAppliedToJob: '#lstPublication_tbl',
        assignmentsOriginSource: '#lstPartenaire_tbl',
        otherCandidateType: '#lstTypeCand_tbl',
        otherFolders: '#IDBannette_tbl',
        otherTypeOfAction: '#lstTypeContactCandidat_tbl'
    };
    var checkFields = {
        folderNew: '#chkVivier\\:1',
        folderInProgress: '#chkVivier\\:4',
        folderHired: '#chkVivier\\:5',
        folderCandidatePool: '#chkVivier\\:6',
        folderCandidateArchive: '#chkVivier\\:7'
    };

    var radioFields = {
        assignmentsCandidateTaggedAsSeenYES: '#optCandidatTraite\\:1',
        assignmentsCandidateTaggedAsSeenNO: '#optCandidatTraite\\:0',
        searchFullTextOnly: '#optEmpoweredSearch\\:boolean',
        searchFullTextANDSemantic: '#optEmpoweredSearch\\:empowered',
        candidateProfileDriversLicenseYES: '#radPermisConduire\\:1',
        candidateProfileDriversLicenseNO: '#radPermisConduire\\:0',
        otherSimilarCandidateYES: '#radDoublonPotentiel\\:1',
        otherSimilarCandidateNO: '#radDoublonPotentiel\\:0',
        otherInternalMobilityYES: '#radMobiliteInterne\\:1',
        otherInternalMobilityNO: '#radMobiliteInterne\\:0',
        otherCompletedYES: '#radEffectue\\:1',
        otherCompletedNO: '#radEffectue\\:0'
    };

    var selectFields = {
        SearchDistanceFromLocation: '#txtMaxRadius'
    };
    var searchedFieldsCount = 0;
    var allSearchedFields = [];

    function adjustTotals(key) {
        searchedFieldsCount++;
        allSearchedFields.push(key);
    }

    function getAllLangs() {
        return jQuery("[id^='lstLangueEditable']");
    }

    function getAllLangLevels() {
        return jQuery("[id^='lstNiveauLangueEditable']");
    }

    function getAllScreeningQuestions() {
        return jQuery("select[id^='vwSqlstSQ'][onchange]");
    }

    //screening answers' html changes depending on the selection on screening question.
    function getAllScreeningAnswers() {
        return jQuery("[id^='vwSqlstSQAnswer']").not("[type='hidden']").not('a');
    }

    function getAllScreeningScores() {
        return jQuery("[id^='vwSqlstSQScore']");
    }

    function trackNotEmpty(obj, fieldName) {
        if (jQuery(obj).length) {
            jQuery(obj).each(function (i) {
                if (jQuery(this).val()) {
                    Luceo.GaShared.logEvent({
                        event: 'candidateSearchField',
                        searchField: fieldName + i
                    });
                    adjustTotals(fieldName + i);
                }
            })
        }
    }


    Search.searchQueries = function () {
        if (window.location.href.indexOf("/bo.php/candidat/liste/recherche") >= 0) {
            Luceo.GaShared.logEvent({
                event: 'completedCandidateSearch',
                SearchKeyword: Luceo.GaShared.undefinedEmptyOrValue(jQuery('#topSearchBartxtMotsCles').attr('value')),
                SearchLocation: Luceo.GaShared.undefinedEmptyOrValue(jQuery('#topSearchBartxtGeoSearch').attr('value')),
                SearchResultsCount: parseInt(jQuery('div.total').text())
            });
        }
    };

    Search.searchedFields = function () {
        jQuery("#btnOK").add("#btnOKMLS").on("click", function () {

            Luceo.GaShared.eachAttribute(txtFields, function (key, value) {
                if (typeof jQuery(value).attr('value') !== typeof undefined &&
                    jQuery(value).attr('value') !== '') {
                    Luceo.GaShared.logEvent({
                        event: 'candidateSearchField',
                        searchField: key
                    });
                    adjustTotals(key);
                }
            });

            Luceo.GaShared.eachAttribute(dateFields, function (key, value) {
                if (typeof jQuery(value).attr('value') !== typeof undefined &&
                    jQuery(value).attr('value') !== '' && jQuery(value).attr('value') !== PsLocal.JS_WEBCALENDAR_DEFAULT) {
                    Luceo.GaShared.logEvent({
                        event: 'candidateSearchField',
                        searchField: key
                    });
                    adjustTotals(key);
                }
            });

            Luceo.GaShared.eachAttribute(tableFields, function (key, value) {
                if (jQuery(value).find('a').length > 0) {
                    Luceo.GaShared.logEvent({
                        event: 'candidateSearchField',
                        searchField: key
                    });
                    adjustTotals(key);
                }
            });

            Luceo.GaShared.eachAttribute(checkFields, function (key, value) {
                if (!Luceo.GaShared.allCandidateFoldersChecked(checkFields)) {
                    if (jQuery(value).is(':checked')) {
                        Luceo.GaShared.logEvent({
                            event: 'candidateSearchField',
                            searchField: key
                        });
                        adjustTotals(key);
                    }
                }
            });

            Luceo.GaShared.eachAttribute(radioFields, function (key, value) {
                if (jQuery(value).is(':checked')) {
                    Luceo.GaShared.logEvent({
                        event: 'candidateSearchField',
                        searchField: key
                    });
                    adjustTotals(key);
                }
            });

            Luceo.GaShared.eachAttribute(selectFields, function (key, value) {
                if (jQuery(value).val() !== '50') {
                    Luceo.GaShared.logEvent({
                        event: 'candidateSearchField',
                        searchField: key
                    });
                    adjustTotals(key);
                }
            });

            trackNotEmpty(getAllLangs(), 'workHistoryLanguages');
            trackNotEmpty(getAllScreeningQuestions(), 'screeningQuestion');
            trackNotEmpty(getAllScreeningScores(), 'screeningScore');


            var langLevels = getAllLangLevels();
            if (jQuery(langLevels).length) {
                jQuery(langLevels).each(function(i) {
                    if (jQuery(this).val() != 'tous') {
                        Luceo.GaShared.logEvent({
                            event: 'candidateSearchField',
                            searchField: 'workHistoryLanguageLevel' + i
                        });
                        adjustTotals('workHistoryLanguageLevel' + i);
                    }
                })
            }

            //the screeningAnswers element can be different depending on user input on screening questions
            //if it is a table element, the id will be appended with _tbl
            //if the table has a child a element, its been used for search
            var screeningAnswers = getAllScreeningAnswers();
            if (jQuery(screeningAnswers).length) {
                jQuery(screeningAnswers).each(function(i) {
                    var id =  (jQuery(this).attr('id'));
                    if (id.search('_tbl') != -1) {
                        if (jQuery(this).has('a').length) {
                            Luceo.GaShared.logEvent({
                                event: 'candidateSearchField',
                                searchField: 'screeningAnswer' + i
                            });
                            adjustTotals('screeningAnswer' + i);
                        }
                    }
                    //if the screening answer is either a select or textbox feild,
                    //if its not empty its been used for search.
                    else {
                        if (jQuery(this).val()) {
                            Luceo.GaShared.logEvent({
                                event: 'candidateSearchField',
                                searchField: 'screeningAnswer' + i
                            });
                            adjustTotals('screeningAnswer' + i);
                        }
                    }
                })
            }


            if (searchedFieldsCount > 0) {
                Luceo.GaShared.logEvent({
                    event: 'candidateSearchSummary',
                    searchFieldSummary: allSearchedFields.join(' - '),
                    searchFieldCount: searchedFieldsCount
                });
            }
        });
    };
})(Luceo.Candidates.Search = Luceo.Candidates.Search || {});
