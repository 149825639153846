(function($)
{
    $.fn.legacyWebFieldsSorter = function(p)
    {
        $.each($('.legacywebfieldssorter'), function (i, e) {
            var element = $(e);
            var composant = new LegacyWebFieldsSorter(element);
            var parent = $(element).parent();
            var content = $('<div />');
            parent.append(content);
            return this;
        });
    };

    var LegacyWebFieldsSorter = function(element){
        if(element === undefined) throw new Error("LegacyWebFieldsSorter: expecting 1 element.");

        var id = element.attr('id');

        var select = $('#' + id + '-list');
        var parent = element.parent();

        var sortable;
        
        var composantLabels = {}
            values = {},
            labels = {};


        init();

        function init() { 
            getComposantLabels();
            buildUi();
            buildSortable();
            populate(select.children('option'));
            deserialize();
            select.change(selectOnChange);
            update();
        }

        function buildUi() {
            
            var header = $('<span />')
                .addClass('header')
                .text(composantLabels.HEADER);

            var addField = $('<span />')
                            .addClass('subheader')
                            .text(composantLabels.ADD_FIELD);

            var dragToOrder = $('<span />')
                            .addClass('subheader')
                            .text(composantLabels.DRAG_TO_ORDER);

            parent
                .prepend(addField)
                .prepend(header)
                .append(dragToOrder);
        }

        function getComposantLabels() {
            composantLabels = JSON.parse(Base64.decode(element.data('labels')));
        }

        function buildSortable() {
            sortable =  $('<ul/>')
                .addClass('is-sortable')
                .sortable({
                    axis: 'y',
                    items: 'li',
                    update : function(){
                        updateOrder();
                    },
                    forcePlaceholderSize : true,
                });
            parent.append(sortable);
        }

        function populate(options) {
            $.each(options, function (index, option) {
                var optionValue = $(option).val();
                values[optionValue] = {
                    'enabled' : false,
                    'mandatory' : false,
                    'order' : 0
                };
                labels[optionValue] = $(this).text();
            });
        }

        function getSortable() {
            return sortable;  
        }

        function selectOnChange() {
            var selectedOption = select.children('option:selected');

            var $template = $('[name$="lstFOFieldsTemplate-list"]');
            $template.val('');
            
            if(selectedOption.val() == '') return false;
            setEnabled(selectedOption.val(), true);
            createSortableElement(selectedOption.val());
            
            updateOrder();
            serialize();
            updateList();

            select.val('');
        }

        function closeOnClick() {
            var id = $(this).data('id');
            setEnabled(id, false);
            updateOrder();
            serialize();
        }

        function setEnabled (key, bool) {
            console.log(key, bool);
            values[key]['enabled'] = bool;
        }

        function setOrder(key, int) {
            values[key]['order'] = int;
        }

        function setMandatory(key, bool) {
            values[key]['mandatory'] = bool;
        }

        function updateOrder (){
            var that = this;
            var ids = sortable.sortable('toArray');
            $.each(ids, function (i, id) {
                var li = $('#' + id);
                setOrder(li.data('id'), i);
            });
            serialize();
            updateList();
        }

        function clearSortable (){
            sortable.html('');
        }

        function createSortableElement (key){
            var liId = id + '-' + key;

            var li = $('<li />')
                .attr('id' , liId)
                .data('id', key);
            
            var label = $('<span />')
                .addClass('label')
                .text(labels[key]);

            var mandatory = $('<input />')
                .attr('type', 'checkbox')
                .attr('id', liId + '-checkbox')
                .click(function () {
                    setMandatory(key, this.checked);
                    serialize();
                });

            var mandatoryLabel = $('<label />')
                .attr('for', liId+ '-checkbox')
                .text(composantLabels.REQUIRED)
                .prepend(mandatory);

            if (values[key].mandatory){
                mandatory.attr('checked', 'checked');
            }

            var close = $('<span />')
                .data('id', key)
                .addClass('close')
                .html('&times;')
                .click(function () {
                    setEnabled(key, false);
                    renderSortable();
                    updateList();
                    serialize();
                });

            li
                .append(label)
                .append(close)
                .append(mandatoryLabel);
                


            sortable.append(li);
        }

        function renderSortable() {
            // First, clear sortable (ul)
            clearSortable();

            var temp = [],
                order = [];

            // Build a temp array of enabled props
            $.each(values, function (key) {
                if (this.enabled) {
                    temp[this.order] = key;    
                }
            });

            // Build an ordered array
            $.each(temp, function (i, key) {
                if (key) {
                    order.push(key);  
                }
            });

            // Create sortable elements (li)
            $.each(order, function (index, key) {
                createSortableElement(key);
            });
        }

        function update() {
            updateList();
            renderSortable();
            serialize();
        }

        function updateList() {
            $.each(values, function (i) {
                if (this.enabled) {
                    select.children('option[value="' + i + '"]').attr('disabled', 'disabled');
                }
                else {
                    select.children('option[value="' + i + '"]').removeAttr('disabled');
                }
            });
        }


        function serialize() {
            var extract = {};
            var extracted = false;

            $.each(values, function (i, j) {
                if (this.enabled) {
                    extracted = true;
                    extract[i] = j;
                }
            });  

            if (extracted) {
                element.val(Base64.encode(JSON.stringify(extract)));
            } else {
                element.val('');
            }
        }

            
        function deserialize() {
            // Check for Base64.decode  or JSON.parse error due to empty/bad data
            try {
                if (element.val() != '') {
                    var extract = Base64.decode(element.val());
                    extract = JSON.parse(extract);
                    $.each(extract, function (key, obj) {
                        if(values[key]){
                            values[key] = obj;
                        }
                    });
                }
                else {
                    return false;
                }  
            }
            catch(err) {
                element.val('');
            }

        }


    };

})(jQuery);

jQuery(document).ready(function () {
    jQuery('.legacywebfieldssorter').legacyWebFieldsSorter();
});
