Luceo.Views = Luceo.Views || {};
Luceo.Views.Requisitions = Luceo.Views.Requisitions || {};

(function (View) {

    jQuery.fn.exists = function(){
        return this.length > 0 ? this : false;
    };

    var $btnSave,
        $btnSaveAsDraft,
        $form;

    var inputs,
        errors;

    View.onLoad = function () {

        if ("jsProperties" in Luceo &&
            "system_code" in Luceo.jsProperties &&
            (Luceo.jsProperties.system_code == "cb1-luceo-test" || Luceo.jsProperties.system_code == "cb1-luceo-e2e")
        ) {
            return false;
        }

        /* Retreive buttons */
        $btnSave = jQuery("#vw1btnSave");
        $btnSaveAsDraft = jQuery("#vw1saveDraft");

        /* Retreive main form */
        $form = jQuery('#MainForm #vw1');

        /* Disable buttons */
        $btnSave.attr("disabled", "disabled");
        $btnSaveAsDraft.attr("disabled", "disabled");

        /* Build list array */
        inputs = buildInputList(jQuery('.input', $form));

        /* Run check on load */
        this.check();

        /* Bind special buttons or elements */
        jQuery('body').on('click', '#trbtnOK', function() {
            View.check();
        });

        jQuery('.input-text-box').focusout(function() {
            View.check();
        });

        jQuery('.input-calendar').select(function() {
            View.check();
        });

        jQuery(document).on('click', '.tbl_l-bit-box-deletebutton', function(e) {
            setTimeout(function() { View.check() }, 200);
            e.preventDefault();
        });

        jQuery('.input-radio').click(function() {
            View.check();
        });

        jQuery('.input-textarea-ml').change(function() {
            View.check();
        });

        // FIXME: This is intended to validate TinyMCE fields but it seems to be broken for the moment
        // jQuery('.input-html').change(function() {
        //     View.check();
        // });

        jQuery('input[type=checkbox]').change(function() {
            View.check();
        });
    };

    View.check = function() {
        View.checkSave();
        View.checkSaveAsDraft();
    }

    View.checkSaveAsDraft = function() {
        var errors = 0;

        var element = findElement('vw1txtIntitule', inputs);

        if (element && !element.validate()) errors++;

        if (errors == 0) {
            $btnSaveAsDraft.removeAttr('disabled');
        } else {
            $btnSaveAsDraft.attr("disabled", "disabled");
        }
    }

    View.checkSave = function() {
        errors = 0;

        jQuery.each(inputs, function(index, input){
            if (input.required) {
                if (!input.validate()) {
                    input.wrapper.addClass('is-error');
                    errors++;
                }
                else {
                    input.wrapper.removeClass('is-error');
                }
            }
        });

        if (errors > 0) {
            if ("reqID" in Luceo.jsProperties && Luceo.jsProperties.reqID == 1) {
                $btnSave.removeAttr("disabled", "disabled");
            } else {
                $btnSave.attr("disabled", "disabled");
            }
        } else {
            $btnSave.removeAttr("disabled", "disabled");
        }

    }

    function buildInputList($inputs) {
        var list = [];

        jQuery.each($inputs, function(index, $input) {
            var element = {
                wrapper: $input,
                required: ($input.hasClass('is-mandatory')) ? true : false,
                getValue: function() {
                    return element.getInput().val();
                },
                validate: function() {
                    return element.getValue() !== '' && element.getValue() !== null;
                }
            };

            // Autocomplete (tbl_l)
            if($input.hasClass('input-text-box')) {
                element.type = 'autocomplete-contact';
                element.getValue = function() {
                    return element.getInput().val();
                }
                element.getInput = function() {
                    return jQuery('input:first-child', element.wrapper);
                }
            }

            // Autocomplete (chosen)
            else if ($input.hasClass('input-select-multiple')) {
                element.type = 'autocomplete-user';
                element.getInput = function() {
                    return jQuery('select', element.wrapper);
                }
                element.validate = function() {
                    return jQuery('input.hidden', element.wrapper).val()
                }
            }

            // Select
            else if ($input.hasClass('input-select')) {
                element.type = 'select';
                element.getInput = function() {
                    return jQuery('select', element.wrapper).exists() || jQuery('input:hidden', element.wrapper).exists();
                }
                element.validate = function() {
                    return element.getInput().find(':selected').val() || element.getInput().val();
                }
            }

            // Text input-calendar
            else if ($input.hasClass('input-calendar')) {
                element.type = 'date';
                element.getInput = function() {
                    return jQuery('input', element.wrapper);
                }
                element.validate = function() {
                    return element.getValue() !== PsLocal.JS_WEBCALENDAR_DEFAULT
                        && element.getValue() !== '';
                }
            }

            /* Multilingual text (or defined as multilingual)*/
            else if ($input.hasClass('input-text-ml')) {
                element.type = 'text-ml';
                element.getInput = function() {
                    return jQuery('input', element.wrapper);
                }
                element.validate = function() {
                    if ("jsProperties" in Luceo && "title-mono-validation" in Luceo.jsProperties) {
                        var titleInput = jQuery(element.getInput()[0]);
                        if (titleInput.is("#vw1txtIntitule")) {
                            return titleInput.val() != '';
                        }
                    }

                    /* If singleLanguage checkbox checked, we skip validation if one field has value */
                    if (document.getElementById('vw1singleLanguage:1') && document.getElementById('vw1singleLanguage:1').checked) {
                        return jQuery(element.getInput()[0]).val() != '';
                    }

                    var errors = 0;
                    /* Only one language */
                    if (element.getInput().length >= 4) {
                        jQuery.each(element.getInput(), function(index, input) {
                            if (index == 1 && jQuery(element.getInput()[0]).val() == '' && jQuery(input).val() == '') errors++;
                            if (index > 1 && jQuery(input).val() == '') errors++;
                        });
                    }
                    if (jQuery(element.getInput()[0]).val() == '') {
                        errors++;
                    }
                    return (errors <= 0);
                }
            }

            /* Multilingual textarea (or defined as multilingual)*/
            else if ($input.hasClass('input-textarea-ml')) {
                element.type = 'textarea-ml';
                element.getInput = function() {
                    return jQuery('input', element.wrapper);
                }
                element.validate = function() {
                    if ("jsProperties" in Luceo) {
                        if ("jd-mono-validation" in Luceo.jsProperties) {
                            var jdText = jQuery('textarea', element.wrapper);
                            if (jdText.is("#vw1txtContexte")) {
                                return jdText.val() != '';
                            }
                        }
                        if ("jr-mono-validation" in Luceo.jsProperties) {
                            var jrText = jQuery('textarea', element.wrapper);
                            if (jrText.is("#vw1txtMission")) {
                                return jrText.val() != '';
                            }
                        }
                    }

                    var errors = 0;
                    /* If multilingual: 1 textarea + 3+ hidden inputs (lang1, lang2,... langN + selected lang */
                    if (element.getInput().length >= 3) {
                        jQuery.each(element.getInput(), function(index, input) {
                            if (index == 0 && jQuery(input).val() == '' && jQuery('textarea', element.wrapper).val() == '') errors++;
                            if (index > 0 && jQuery(input).val() == '') errors++;
                        });
                    }
                    if (jQuery('textarea', element.wrapper).val() == '') {
                        errors++;
                    }
                    return (errors <= 0);
                }
            }

            /* Simple text */
            else if ($input.hasClass('input-text')) {
                element.type = 'text';
                element.getInput = function() {
                    return jQuery('input', element.wrapper);
                }
            }

            /* Radio buttons */
            else if ($input.hasClass('input-radio')) {
                element.type = 'radio';
                element.getInput = function() {
                    return jQuery(element.wrapper);
                }
                element.validate = function() {
                    return jQuery('input[type=radio]:checked', element.wrapper).length > 0;
                }
            }

            else if ($input.hasClass('input-position')) {
                element.type = 'position';
                element.getInput = function() {
                    return jQuery(element.wrapper);
                }
                element.validate = function() {
                    return jQuery('select', element.wrapper).find(':selected').val()
                        || jQuery('input[type=checkbox]:checked', element.wrapper).exists();
                }
            }

            /* if element is supported */
            if (element.type) {
                list.push(element);

                if (element.type == 'text-ml') {
                    jQuery.each(element.getInput(), function(index, input) {
                        jQuery(input).on('blur keyup change', function() {
                            View.check();
                        });
                    });
                } else {
                    if (element.getInput().attr('id')) {
                        jQuery('body').on('blur keyup change', '#' + element.getInput().attr('id'), function(e) {
                            View.check();
                        });
                    }
                }
            } else {
                console.log('Unsupported:', {
                    element: element,
                    wrapper: element.wrapper
                });
            }
        });

        return list;
    }

    function findElement(findElement, inputs) {
        var found;
        jQuery.each(inputs, function(index, input) {
            if (input.getInput().attr('id') == findElement) {
                found = input;
            }
        });
        return found;
    }
})(Luceo.Views.Requisitions.Validation = Luceo.Views.Requisitions.Validation || {});
