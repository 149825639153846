Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Candidates = Luceo.Views.Candidates || {};
Luceo.Views.Candidates.Messenger = Luceo.Views.Candidates.Messenger || {};
Luceo.Views.Candidates.Messenger.Confirm = Luceo.Views.Candidates.Messenger.Confirm || {};

(function (Confirm, $) {
    function recalculateConsent() {
        jQuery.ajax({
            url: '/' + window._psfc + '.php/frontgateway/sms-consent/update',
            method: 'POST',
            dataType: 'json',
            data: jQuery('.sms-recalculate').attr('data-userid')
        }).done(function () {
            jQuery('#retroactive-popup-text').html(PsLocal.TXT_RETROACTIVE_CONSENT_POPUP_PROCESSING);
            jQuery('.sms-recalculate').prop('disabled', true);
        }).fail(function() {
            jQuery('#retroactive-popup-text').html(PsLocal.TXT_RETROACTIVE_CONSENT_POPUP_ERROR);
            jQuery('.sms-recalculate').prop('disabled', true);
        });
    }

    Confirm.recalculateConsent = recalculateConsent;
})(Luceo.Views.Candidates.Messenger.Confirm, jQuery);
