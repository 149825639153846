(function($)
{
    $.fn.webFieldsSorter = function(p)
    {
        $.each($('.webfieldssorter'), function (i, e) {
            var element = $(e);
            var composant = new WebFieldsSorter(element);
            var parent = $(element).parent();
            var content = $('<div />');
            parent.append(content);
            return this;
        });
    };

    var WebFieldsSorter = function(element){
        if(element === undefined) throw new Error("WebFieldsSorter: expecting 1 element.");

        var id = element.attr('id');
        var isUpliftedUI = Luceo.jsProperties.upliftedUI;

        var select = $('#' + id + '-list');

        var optionMaxLength = 130;
        jQuery('option', select).each(function(){
            var text = jQuery(this).text();
            if (text.length > optionMaxLength) {
                jQuery(this).text(text.substr(0, optionMaxLength) + '...');
                jQuery(this).attr('title', text);
            } else {
                jQuery(this).text(text);
            }
        })

        var parent = element.parent();

        var sortable;

        var composantLabels = {},
            webcontrolDatas = {},
            values = {},
            labels = {};


        init();

        function init() {
            getComposantLabels();
            getWebcontrolDatas();
            populate(select.find('option'));
            buildUi();
            buildSortable();
            deserialize();
            select.on('change', selectOnChange);
            update();
        }

        function buildUi() {

            var header = $('<span />')
                .addClass('header')
                .text(composantLabels.HEADER);

            var addField = $('<span />')
                            .addClass('subheader')
                            .text(composantLabels.ADD_FIELD);

            var dragToOrder = $('<span />')
                            .addClass('subheader')
                            .text(composantLabels.DRAG_TO_ORDER);

            parent
                .prepend(addField)
                .prepend(header)
                .append(dragToOrder);
        }

        function getComposantLabels() {
            composantLabels = JSON.parse(Base64.decode(element.data('labels')));
        }

        function getWebcontrolDatas() {
            webcontrolDatas = JSON.parse(Base64.decode(element.data('webcontrol')));
        }

        function buildSortable() {
            sortable =  $('<ul/>')
                .addClass('is-sortable')
                .sortable({
                    axis: 'y',
                    items: 'li',
                    update : function(){
                        updateOrder();
                    },
                    forcePlaceholderSize : true,
                });
            parent.append(sortable);
        }

        function populate(options) {
            $.each(options, function (index, option) {
                var optionValue = $(option).val();
                values[optionValue] = {
                    'enabled' : false,
                    'mandatory' : false,
                    'order' : 0,
                    'label' : {}
                };
                labels[optionValue] = $(this).text();
            });

            if (values['receivetexting']) {
                values['receivetexting'].enabled = true;
                values['receivetexting'].mandatory = true;
                values['receivetexting'].order = Object.keys(values).length - 1;
            }
        }

        function getSortable() {
            return sortable;
        }

        function selectOnChange() {
            var selectedOption = select.find(":selected");

            if(selectedOption.val() == '') return false;
            key = createSortableElement(selectedOption.val());
            setEnabled(key, true);

            updateOrder();
            serialize();
            updateList();
            // I have no idea why the selectOnChange will be triggerd twice. It will insert the same field in this form.
            // So I use the renderSortable() to deduplicate the fields.
            renderSortable();

            select.val('');
            logEvent({
                event: 'addFormField',
                requisition: webcontrolDatas.requisitionId,
                selectedField:selectedOption.val()
            });
        }

        function closeOnClick() {
            var id = $(this).data('id');
            setEnabled(id, false);
            updateOrder();
            serialize();
        }

        function setEnabled (key, bool) {
            if (values[key]) {
                values[key]['enabled'] = bool;
            }
        }

        function setOrder(key, int) {
            values[key]['order'] = int;
        }

        function setMandatory(key, bool) {
            values[key]['mandatory'] = bool;
        }

        function setLabel(key, val) {
            if (!values[key]) {
                values[key] = {};
            }
            values[key]['label'][webcontrolDatas.currentLanguage] = val;
        }

        function updateOrder (){
            var that = this;
            var ids = sortable.sortable('toArray');
            $.each(ids, function (i, id) {
                var li = $('#' + id);
                setOrder(li.data('id'), i);
            });
            serialize();
            updateList();
        }

        function clearSortable (){
            sortable.html('');
        }

        function generateRandomKey(length)
        {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for( var i=0; i < length; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }

        function getKey(key)
        {
            if (key == 'textblock' || key == 'fieldgroup') {
                origKey = key;
                var i = generateRandomKey(5);
                while($.inArray(key + '-' + i, values) != -1) {
                    i = generateRandomKey(5);
                }
                key = key + '-' + i;
                values[key] = $.extend(true, {}, values[origKey]); // make a clone object
            }
            return key;
        }

        function getMultipleFieldsLabel(key)
        {
            if (key.indexOf('textblock') >= 0) {
                return labels['textblock'];
            }
            if (key.indexOf('fieldgroup') >= 0) {
                return labels['fieldgroup'];
            }
        }

        function isMultipleFieldsKey(key)
        {
            return key == 'textblock' || key.indexOf('textblock') >= 0
                || key == 'fieldgroup' || key.indexOf('fieldgroup') >= 0;
        }

        function renderError(key)
        {
            if (key.indexOf('textblock') >= 0 || key.indexOf('fieldgroup') >= 0) {
                var oneEmpty = false;
                if ($.isEmptyObject(values[key]['label'])) {
                    oneEmpty = true;
                } else {
                    $.each(JSON.parse(webcontrolDatas.langs), function(i, el) {
                        if (!values[key]['label'][el]) {
                            oneEmpty = true;
                        }
                    });
                }
                if (webcontrolDatas.displayErrors && oneEmpty) {
                    return true;
                }
            }
            return false;
        }

        function createSortableElement (key){
            if (key != '') {
                key = getKey(key);

                var liId = id + '-' + key;

                var li = $('<li />')
                    .attr('id' , liId)
                    .data('id', key);

                var label = $('<span />')
                    .addClass('label');

                if (typeof(labels[key]) != 'undefined') {
                    label.text(labels[key]);
                } else {
                    label.text(getMultipleFieldsLabel(key));
                }

                if (!isMultipleFieldsKey(key)) {
                    if (isUpliftedUI) {
                        var mandatory = $('<input />')
                        .attr('type', 'checkbox')
                        .attr('id', liId + '-checkbox')
                        .click(function () {
                            setMandatory(key, this.checked);
                            serialize();
                        });

                        var mandatorySpan = $('<span />')
                            .text(composantLabels.REQUIRED);

                        var mandatoryDivText = $('<label />')
                            .attr('for', liId+ '-checkbox')
                            .attr('class', 'checkbox-label')
                            .prepend(mandatorySpan);

                        var mandatoryLabel = $('<div />')
                            .attr('class', 'checkbox-div')
                            .prepend(mandatoryDivText)
                            .prepend(mandatory);

                        if (values[key].mandatory){
                            mandatory.prop('checked', 'checked');
                        }

                    } else {
                        var mandatory = $('<input />')
                        .attr('type', 'checkbox')
                        .attr('id', liId + '-checkbox')
                        .click(function () {
                            setMandatory(key, this.checked);
                            serialize();
                        });

                        var mandatoryLabel = $('<label />')
                            .attr('for', liId+ '-checkbox')
                            .text(composantLabels.REQUIRED)
                            .prepend(mandatory);

                        if (values[key].mandatory){
                            mandatory.prop('checked', 'checked');
                        }
                    }
                }

                if (key.indexOf('textblock') >= 0 || key.indexOf('fieldgroup') >= 0) {
                    if (isUpliftedUI) {
                        var textblock = $('<textarea />')
                            .attr('type', 'textarea')
                            .attr('id', key)
                            .focusout(function () {
                                setLabel(key, $(this).val());
                                serialize();
                            })
                            .keyup(function () {
                                textAreaAdjust(this)
                            })
                        ;
                    } else {
                        var textblock = $('<textarea />')
                            .attr('type', 'textarea')
                            .attr('id', key)
                            .focusout(function () {
                                setLabel(key, $(this).val());
                                serialize();
                            })
                        ;
                    }
                    if (values[key] && values[key]['label'][webcontrolDatas.currentLanguage]){
                        textblock.val(values[key]['label'][webcontrolDatas.currentLanguage]);
                    }
                }

                if (renderError(key)) {
                    li.addClass('err-missing-field');
                }

                var close = $('<span />')
                    .data('id', key)
                    .addClass('close')
                    .html('&times;')
                    .click(function () {
                        setEnabled(key, false);
                        renderSortable();
                        updateList();
                        serialize();
                        logEvent({
                            event: 'removeFormField',
                            requisition: webcontrolDatas.requisitionId,
                            selectedField:key
                        });
                    });

                li
                    .append(label)
                    .append(close)
                    .append(mandatoryLabel)
                    .append(textblock);

                if (key == 'receivetexting') {
                    close.remove();
                    mandatoryLabel.remove();
                }
                sortable.append(li);
                return key;
            }
        }

        function renderSortable() {
            // First, clear sortable (ul)
            clearSortable();

            var temp = [],
                order = [];

            // Build a temp array of enabled props
            $.each(values, function (key) {
                if (this.enabled) {
                    temp[this.order] = key;
                }
            });

            //remove the null element to make (texting Consent)'s order be the last
            temp = temp.filter(function (obj) { return obj } );

            // Build an ordered array
            $.each(temp, function (i, key) {
                if (key) {
                    order.push(key);
                }
            });

            // Create sortable elements (li)
            $.each(order, function (index, key) {
                createSortableElement(key);
            });
        }

        function update() {
            updateList();
            renderSortable();
            serialize();
        }

        function updateList() {
            $.each(values, function (i) {
                if (this.enabled) {
                    if (i != 'textblock' || i != 'fieldgroup') {
                        select.find('option[value=' + i + ']').attr('disabled', 'disabled');
                        if (isUpliftedUI) {
                            select.parent('div.select-div').find('li[rel="' + i + '"]').addClass('disabled-li');
                        }
                    }
                } else {
                    select.find('option[value="' + i + '"]').removeAttr('disabled');
                    if (isUpliftedUI) {
                        select.parent('div.select-div').find('li[rel="' + i + '"]').removeClass('disabled-li');
                    }
                }
            });
        }


        function serialize() {
            var extract = {};
            var extracted = false;

            $.each(values, function (i, j) {
                if (this.enabled) {
                    extracted = true;
                    extract[i] = j;
                }
            });

            if (extracted) {
                // On the apply form, the encoded result is used to check if it matches a template.
                // However, `json_encode` in PHP by default escapes non-ASCII characters, which behaves differently with
                // `JSON.stringify` in JS. As a result, no template would be matched if there's any non-ASCII
                // characters. So we escape non-ASCII characters here as well.
                element.val(Base64.encode(escapeUnicode(JSON.stringify(extract))));
            } else {
                element.val('');
            }
        }

        // escape non-ASCII characters to show correctly
        function escapeUnicode(str) {
            return str.replace(/[\u0080-\uFFFF]/g, function(ch) {
                return '\\u' + ('000' + ch.charCodeAt(0).toString(16)).slice(-4);
            });
        }

        function deserialize() {
            // Check for Base64.decode  or JSON.parse error due to empty/bad data
            try {
                if (element.val() != '') {
                    var extract = Base64.decode(element.val());
                    extract = JSON.parse(extract);
                    $.each(extract, function (key, obj) {
                        if(values[key]){
                            values[key] = obj;
                        }
                        if (key.indexOf('textblock') >= 0 || key.indexOf('fieldgroup') >= 0) {
                            values[key] = obj;
                        }
                    });
                }
                else {
                    return false;
                }
            }
            catch(err) {
                element.val('');
            }
        }

        function isProd() {
            return webcontrolDatas.isProd;
        }

        function logEvent(data) {
            if ((typeof dataLayer !== 'undefined') && isProd()) {
                dataLayer.push(data);
            } else if (!isProd()) {
                console.log(data);
            }
        }
    };

})(jQuery);

jQuery(document).ready(function () {
    jQuery('.webfieldssorter').webFieldsSorter();
});
