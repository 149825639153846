Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Candidates = Luceo.Views.Candidates || {};
Luceo.Views.Candidates.List = Luceo.Views.Candidates.List || {};
(function (View) {
    View.onLoad = function () {
        Luceo.Candidates.Search.searchedFields();
        Luceo.Candidates.Search.searchQueries();

        jQuery('.is-form select[multiple=multiple]').change(function(){
            jQuery('#btnOK').trigger('click');
        });

    };
})(Luceo.Views.Candidates.List.Search = Luceo.Views.Candidates.List.Search || {});
