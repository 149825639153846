// Keep the already Selected Value of the drop down.
var $selectedLiValue;
(function ($) {
    $.fn.select = function () {
        Select($(this));
        return this;
    };

    var Select = function (element) {
        if (element === undefined) throw new Error("Select: expecting 1 element.");

        var $this = $(element);
        if ((Luceo.jsProperties && !Luceo.jsProperties.upliftedUI) || $this.attr('multiple')) {
            return true;
        }

        var $selectedDiv = $this.parent('div.select-div');
        var $list = $selectedDiv.find("ul");
        var $styledSelect = $selectedDiv.find(".select-styled");
        var defaultSelected = $this.children('option').eq(0).text();
        if ($styledSelect.find('span').text() == '') {
            $styledSelect.find('span').text(defaultSelected);
        }

        $($selectedDiv).on('click focusin', function (e) {
            e.stopPropagation();
            $selectedLiValue = $(this).find('span').text();
            $('div.select-div.active-select').not(this).each(function () {
                $(this).removeClass('active-select');
                $(this).find('ul.select-options').hide();
            });
            $(this).addClass('active-select');
            $(this).find('ul.select-options').show();
        });
        $($selectedDiv).on('focusout', function (e) {
            e.stopPropagation();
            $(this).removeClass('active-select');
            $(this).find('ul.select-options').hide();
        });
        $($selectedDiv).on('mousedown', '.select-options > li', function (e) {
            // To avoid to run multiple Ajax queries
            if ($(this).attr('rel') != $this.val()) {
                e.stopPropagation();
                $styledSelect.find('span').text($(this).text()).attr('rel', $(this).attr('rel'));
                $this.val($(this).attr('rel')).change();
                $list.hide();
            }
        });
    }

})(jQuery);

jQuery(document).ready(function () {
    var $keyStrokesBuffer = '';
    var $timer = 250; // Set the timeout between 2 keystrokes as .25 sec
    var $timeouts;
    jQuery(document).on('keydown', function (e) {
        var $list = jQuery('div.select-div.active-select ul.select-options');
        if ($timeouts) {
            clearTimeout($timeouts);
        }
        $currentKeyStroke = e.keyCode;

        // If the keystroke is equivalent to tab or enter, keep the selected value and close the dropdown.
        if ($currentKeyStroke == 9 || $currentKeyStroke == 13) {
            closeDropDown(true);
            if ($timeouts) {
                $keyStrokesBuffer = '';
                clearTimeout($timeouts);
            }
            return true;
        }

        // If the keystroke is equivalent to ESC, if there is some value selected already, use that else keep it to the default.
        if ($currentKeyStroke == 27) {
            if ($selectedLiValue != "") {
                searchLiElement($selectedLiValue.toUpperCase(), $list);
                closeDropDown();
                if ($timeouts) {
                    $keyStrokesBuffer = '';
                    clearTimeout($timeouts);
                }
                return true;
            }
        } else {
            $keyStrokesBuffer = $keyStrokesBuffer + String.fromCharCode($currentKeyStroke);
            if ($list.is(':visible')) {
                searchLiElement($keyStrokesBuffer, $list);
            }
        }
        $timeouts = setTimeout(function () {
            $keyStrokesBuffer = '';
        }, $timer);
    });

    jQuery.each(jQuery('select'), function (i, e) {
        jQuery(this).select();
    });

    jQuery(document).click(function () {
        closeDropDown();
    });
});

function closeDropDown(setSelectedValue) {
    var $selectedDiv = jQuery('div.select-div.active-select');
    var $list = $selectedDiv.find('ul.select-options');
    $list.scrollTop(0);
    $list.hide();
    $list.find('li').removeClass('highlight');
    if (setSelectedValue) {
        var $selectedLiValue = $selectedDiv.find('.select-styled span').attr('rel');
        var $selectDropDown = $selectedDiv.find('select');
        $selectDropDown.val($selectedLiValue).change();
    }
}

function searchLiElement($keyStrokesBuffer, $list) {
    jQuery('ul.select-options li').removeClass('highlight');
    $liElement = jQuery('div.select-div.active-select ul.select-options li[search-attr^="' + $keyStrokesBuffer + '"]').first();
    $liElement.addClass('highlight');

    if ($liElement.length) {
        $list.scrollTop(
            $liElement.offset().top - $list.offset().top + $list.scrollTop()
        );
        var $span = jQuery('div.select-div.active-select .select-styled').find('span');
        $span.text($liElement.text());
        $span.attr('rel', $liElement.attr('rel'));
    }
}

