Luceo = window.Luceo || {};
Luceo.Ofccp = Luceo.Ofccp || {};

(function (Ofccp, $) {
    var config = {
        enabled: false,
        popupUrl: ""
    };

    var status = {
        hasContext: false,
        paused: false
    };

    function init(conf) {
        if (!'enabled' in conf || !'hasContext' in conf || !'paused' in conf || !'popup_url' in conf) {
            throw new Error("Luceo.Ofccp.initConfig: Invalid OFCCP config");
        }
        config.enabled = conf.enabled;
        config.popupUrl = conf.popupUrl;
        status.hasContext = conf.hasContext;
        status.paused = conf.paused;
        contextUpdated();
    }

    function shouldDisplayPopup() {
        return config.enabled
            && !status.hasContext
            && !status.paused;
    }

    function enforceContext() {
        if (shouldDisplayPopup()) {
            // disables actions
            updateActionsButton(true);

            // shows popup
            showPopup();
        } else {
            updateActionsButton(false);
        }
    }

    function updateContext(hasContext, paused) {
        status.hasContext = hasContext;
        status.paused = paused;
        contextUpdated();
    }

    function contextUpdated() {
        updateActionsButton(shouldDisplayPopup());
    }

    // shows the ofccp context popup
    function showPopup() {
        var id = 'ofccp_popup';

        PsDialog.create(id, {
            loadView: true,
            viewId: id,
            viewUrl: config.popupUrl,
            stripScripts: false
        }, {
            cache: false
        });
    }

    function updateActionsButton(disabled) {
        $('input[data-ofccp-disable=true]').each(function (i, e) {
            $(e).prop('disabled', disabled);
        });
    }

    // exports
    Ofccp.init = init;
    Ofccp.shouldDisplayPopup = shouldDisplayPopup;
    Ofccp.enforceContext = enforceContext;
    Ofccp.updateContext = updateContext;

})(Luceo.Ofccp, jQuery);
