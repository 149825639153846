Luceo = window.Luceo || {};
Luceo.Tools = Luceo.Tools || {};

(function (Checkboxes) {
    'use strict';

    Checkboxes.getIndeterminate = function(array) {
        var indeterminate = true,
            checked;
            
        var countFalse = array.map(function(){
                return this.checked;
            }).toArray().filter(function(value){
                return value === false;
            }).length;

            /* If all checkboxes are checked */
            if (countFalse == 0) {
                indeterminate = false;
                checked = true;
            } 
            /* If all checkboxes are unchecked */
            else if (countFalse == array.length) {
                indeterminate = false;
                checked = false;
            }

            return {
                indeterminate: indeterminate,
                checked: checked
            };
    }

})(Luceo.Tools.Checkboxes = Luceo.Tools.Checkboxes || {});