Luceo = window.Luceo || {};

(function (Form) {

    Form.disableSubmitButton = function (){
        jQuery('input[type=submit]')
            .addClass('js-isSubmit')
            .prop('type', 'button');
    }

    Form.enableSubmitButton = function (){
        var visible = 0;
        // Check if at least 1 popin still displayed 
        jQuery.each(PsWindowManager.getInstance().windows, function (i, j) {
            if (j && j.visible && j.modal) {
                visible++;
            }
        });

        if (! visible) {
            jQuery('input.js-isSubmit')
                .removeClass('js-isSubmit')
                .prop('type', 'submit');
        }
    }

})(Luceo.Form = Luceo.Form || {});