Luceo = window.Luceo || {};

(function (Walkme) {

    jQuery(document).ready(function () {
        run();
    })

    function run() {

        if ('walkme' in Luceo.jsProperties) {

            /* Prod */
            var dev = false,
                src = 'https://cdn.walkme.com/users/c2310068044b44e3b4c17dea625cecbf/walkme_c2310068044b44e3b4c17dea625cecbf_https.js';

            /* Dev */
            if ('env' in Luceo.jsProperties && 'dev' in Luceo.jsProperties.env) {
                dev = true;
                src = 'https://cdn.walkme.com/users/c2310068044b44e3b4c17dea625cecbf/test/walkme_c2310068044b44e3b4c17dea625cecbf_https.js';
            }

            window.walkme_variables = Luceo.jsProperties.walkme;
            window.walkme_variables.system_env = (dev) ? 'dev' : 'prod';

            var walkme = document.createElement('script');
            walkme.type = 'text/javascript';
            walkme.async = true;
            walkme.src = src;
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(walkme, s);
            window._walkmeConfig = { smartLoad: true };
        }
    }


}(Luceo.Walkme = Luceo.Walkme || {}));
