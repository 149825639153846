Luceo = window.Luceo || {};
Luceo.Ofccp = Luceo.Ofccp || {};

(function($, Ofccp)
{
    $.fn.checkOfccpContext = function() {
        $('input[data-ofccp-context=true]').each(function (i, e) {
            $(e).focusin(function(){
                Ofccp.enforceContext();
            });
        });
    };
})(jQuery, Luceo.Ofccp);

jQuery(document).ready(function () {
    if ('jsProperties' in Luceo && 'ofccp' in Luceo.jsProperties) {
        Luceo.Ofccp.init(Luceo.jsProperties.ofccp);
        jQuery('input[data-ofccp-context=true]').checkOfccpContext();
    }
});
