Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Requisitions = Luceo.Views.Requisitions || {};

(function (View) {

    var $table,
        $tbody,

        $draftUser,
        $draftUserUl,
        $draftAlert,

        $rowTemplate;

    View.onLoad = function () {

        $rowTemplate = Luceo.Templates.get('view/drafts/row');

        $table = jQuery('#drafts_table');
        $tbody = jQuery('tbody', $table);

        $draftAlert = jQuery('.is-draft-alert');
        $draftUser = jQuery('.is-draft-user');

        if (Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
            $draftUserUl = jQuery('.is-draft-user').parent("div.select-div").find("ul");
        }
        $draftUser.change(function(e) {
            e.preventDefault();
            View.loadData();
        });

        View.loadUsers();
        View.loadData();
    };

    View.loadUsers = function() {

        jQuery.ajax({
            url: '/' + window._psfc + '.php/frontgateway/users',
            context: document.body
        })
        .done(function(data) {
            try {
                jQuery.each(data, function(i, user){
                    if (! user.lastname == '') {
                        var $option = jQuery('<option />')
                            .val(user.id)
                            .text(user.firstname + ' ' + user.lastname);
                        $draftUser.append($option);

                        if (Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
                            var $li = jQuery('<li />')
                            .attr('rel',user.id)
                            .text(user.firstname + ' ' + user.lastname);
                            $draftUserUl.append($li);
                        }
                    }
                })
            } catch (error) {
                View.displayError('An error occurred while displaying the user list.');
            }
        })
        .fail(function() {
            View.displayError('An error occurred while retrieving the user list.');
        });
    }

    View.loadData = function(id) {
        $tbody.html('');

        // Filter by user ID
        var userId = $draftUser.val();
        var data = {};
        if (userId > 0) {
            data.id = userId
        }

        jQuery.ajax({
            url: '/' + window._psfc + '.php/frontgateway/drafts',
            context: document.body,
            method: 'GET',
            data: data
        })
        .done(function(data) {
            $tbody.html('');
            try {
                jQuery.each(data.drafts, function(i, row){
                    row.finish_requisition = PsLocal.TXT_DRAFT_LIST_FINISH_REQUISITION;
                    if (row.source_app == 'ats') {
                        row.url = "dossier?tab=form&readonly=0&id=" + row.id;
                    } else {
                        row.url = 'dossier?tab=form&new=true&readonly=0&draft=' + row.id;
                    }
                    row.created = moment(row.created).format('l');
                    $tbody.append(Mustache.render($rowTemplate, row));
                });
                if (data.error) {
                    View.displayError(data.error);
                }
            } catch(error) {
                View.displayError('An error occurred while displaying the draft list.');
            }
        })
        .fail(function() {
            View.displayError('An error occurred while retrieving the draft list.');
        });
    }

    View.displayError = function(message) {
        $draftAlert.show();
        jQuery('.is-draft-alert-content', $draftAlert).text(message);
    }

})(Luceo.Views.Requisitions.Draft = Luceo.Views.Requisitions.Draft || {});
