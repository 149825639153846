Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Candidates = Luceo.Views.Candidates || {};

(function (View) {
    'use strict';

    var $container,
        $middle,
        $input,
        $popup,
        $icon

    View.onLoad = function () {
        $container = jQuery('#checkpolicy_div');
        $container.attr('style', 'padding:0 25%; top: 200px; word-break: break-word; font-size: medium');

        $input = $container.find('input');
        $input.attr('style', 'margin: 5px -5px 5px 20px');

        $popup = $container.find('.popup');
        $popup.attr('style', 'text-align: justify !important; padding: 5% 7% 2% 7%');

        $middle = $container.find('.middle_pu');
        $middle.attr('style', 'padding: 0 !important');

        $icon = $container.find('i');
        $icon.remove();
    }
})(Luceo.Views.Candidates.CheckPolicy = Luceo.Views.Candidates.CheckPolicy || {});
