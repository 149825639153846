Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};

(function (View) {
    'use strict';

    var $container,
        $errorContainer,

        ticket,
        base,
        path,
        url;

    View.onLoad = function() {
        $container = jQuery('.reporting-tableau');
        $errorContainer = jQuery('.tableau-error');

        ticket = $container.data('ticket') || null;
        base = Luceo.jsProperties.tableauBaseUrl || null; 
        path = '/trusted/' + ticket + (Luceo.jsProperties.tableauView || null);
        url = base + path;

        /* Check if ticket exists */

        if (ticket == '-1' || ticket == null) {
            this.showTableauConnexionError();
            return false;
        }

        var localStorageTicketID = 'tableau-token' + ticket;

        /* Check if ticket has been used previously */
        if (localStorage.getItem(localStorageTicketID)){
            $container.hide();
            location.reload(true);
        }

        localStorage.setItem(localStorageTicketID,'true');

        View.initTableau();
    }

    View.initTableau = function () {

        var options = {
           width: "100%",
           height: "1000px"
        };

        var containerDiv = document.getElementById("vizContainer"),
        viz = new tableau.Viz(containerDiv, url, options);
    }

    View.showTableauConnexionError = function () {
        $errorContainer.show();
    }

})(Luceo.Views.Reporting = Luceo.Views.Reporting || {});
