Luceo = window.Luceo || {};
Luceo.Bureau = Luceo.Bureau || {};
(function (Quicksearch) {

    var txtFields = {
        keywords: '#blsearchDesktxtMotsCles',
        location: '#blsearchDesktxtGeoSearch',
        lastName: '#blsearchDesktxtNom',
        firstName: '#blsearchDesktxtPrenom',
        postingId: '#blsearchDesktxtReference',
        postingWhichCandidateRepliedTo: '#blsearchDesklstPublication',
        jobPosition: '#blsearchDesklstPoste',
        comment: '#blsearchDesktxtCommentaire',
        trainingDetails: '#blsearchDesktxtFormationTexte',
        skillsDetails: '#blsearchDesktxtCompetencesTexte',
        observations: '#blsearchDesktxtObservationRech',
        reasonForRequest: '#blsearchDesktxtMotifDemandeMob',
        assignment: '#blsearchDesktxtAffectationMob',
        contraindication: '#blsearchDesktxtContreIndicationIntegr',
        assignmentFollowUp: '#blsearchDesktxtSuiviAffectation',
        report: '#blsearchDesktxtBilan',
        trainings: '#blsearchDesktxtEvaluation',
        absenceSickLeave: '#blsearchDesktxtAbsence',
        comments: '#blsearchDesktxtRestrictionMed',
        motivations: '#blsearchDesktxtMotivations',
        specialities: '#blsearchDesktxtCantineSpecialite',
        experience: '#blsearchDesktxtCantineExperience'
    };

    var dateFields = {
        birthDate: '#blsearchDeskdtDateNaissance'
    };
    var tableFields = {
        jobCategory: '#blsearchDesklstFiliere_tbl',
        jobPosition: '#blsearchDesklstMetier_tbl',
        jobStatus: '#blsearchDesklstDernierEtat_tbl',
        mobility: '#blsearchDesklstMobilite_tbl',
        employmentType: '#blsearchDesklstTypeContrat_tbl',
        luceoUser: '#blsearchDesklstUtilisateur_tbl',
        competition: '#blsearchDesklstConcours_tbl',
        categories: '#blsearchDesklstCategorie_tbl',
        situation: '#blsearchDesklstSituation_tbl',
        socialCriterias: '#blsearchDesklstCritereSociaux_tbl',
        diplomas: '#blsearchDesklstNomDiplome_tbl',
        departmentGeo: '#blsearchDesklstDepartementGeo_tbl',
        service: '#blsearchDesklstService_tbl',
        country: '#blsearchDesklstPaysRef_tbl',
        state: '#blsearchDesklstRegion_tbl'
    };
    var checkFields = {
        folderNew: '#blsearchDeskchkVivier\\:1',
        folderInProgress: '#blsearchDeskchkVivier\\:4',
        folderHired: '#blsearchDeskchkVivier\\:5',
        folderCandidatePool: '#blsearchDeskchkVivier\\:6',
        folderCandidateArchive: '#blsearchDeskchkVivier\\:7'
    };

    var radioFields = {
        statutoryYES: '#blsearchDeskradStatutaire\\:1',
        statutoryNO: '#blsearchDeskradStatutaire\\:0',
        fullTextSearch: '#blsearchDeskoptEmpoweredSearch\\:boolean',
        fullTextANDSemanticSearch: '#blsearchDeskoptEmpoweredSearch\\:empowered'
    };

    var selectFields = {
        distanceMaxRadius: '#blsearchDesktxtMaxRadius'
    };
    var searchedFieldsCount = 0;
    var allSearchedFields = [];

    function adjustTotals(key) {
        searchedFieldsCount++;
        allSearchedFields.push(key);
    }

    Quicksearch.searchedFields = function () {
        jQuery("#blsearchDeskbtnMmBureauOK").add("#blsearchDeskbtnMmBureauOKMLS").on("click", function () {

            Luceo.GaShared.eachAttribute(txtFields, function (key, value) {
                if (typeof jQuery(value).attr('value') !== typeof undefined &&
                    jQuery(value).attr('value') !== '') {
                    adjustTotals(key);
                }
            });

            Luceo.GaShared.eachAttribute(dateFields, function (key, value) {
                if (typeof jQuery(value).attr('value') !== typeof undefined &&
                    jQuery(value).attr('value') !== '' && jQuery(value).attr('value') !== PsLocal.JS_WEBCALENDAR_DEFAULT) {

                    adjustTotals(key);
                }
            });

            Luceo.GaShared.eachAttribute(tableFields, function (key, value) {
                if (jQuery(value) !== typeof undefined &&
                    jQuery(value).find('a').length > 0) {
                    adjustTotals(key);
                }
            });

            Luceo.GaShared.eachAttribute(checkFields, function (key, value) {
                if (!Luceo.GaShared.allCandidateFoldersChecked(checkFields)) {
                    if (jQuery(value) !== typeof undefined &&
                        jQuery(value).is(':checked')) {
                        adjustTotals(key);
                    }
                }
            });

            Luceo.GaShared.eachAttribute(radioFields, function (key, value) {
                if (jQuery(value) !== typeof undefined &&
                    jQuery(value).is(':checked')) {
                    adjustTotals(key);
                }
            });

            Luceo.GaShared.eachAttribute(selectFields, function (key, value) {
                if (jQuery(value).val() !== typeof undefined &&
                    jQuery(value).val() !== '50') {
                    adjustTotals(key);
                }
            });

            if (searchedFieldsCount > 0) {
                Luceo.GaShared.logEvent({
                    event: 'candidateQuickSearchSummary-Home',
                    searchFieldSummary: allSearchedFields.join(' - '),
                    searchFieldCount: searchedFieldsCount
                });
            }
        });
    };
})(Luceo.Bureau.Quicksearch = Luceo.Bureau.Quicksearch || {});
