Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};

(function (View) {
    'use strict';
    
    var currentStep = 'upload',
        icons = {
            info:    '<i class="fa fa-info-circle" aria-hidden="true"></i> ',
            loading: '<i class="fa fa-refresh fa-spin" aria-hidden="true"></i> '
        },

        language = 'en',

        steps = {
            upload: {
                panel: null,
                tab: null,
                label: null,
                position: 1
            },
            mapping: {
                panel: null,
                tab: null,
                label: null,
                position: 2
            },
            prepare: {
                panel: null,
                tab: null,
                label: null,
                position: 3
            },
            result: {
                panel: null,
                tab: null,
                label: null,
                position: 4
            }
        },
        
        translations,
        currentStepErrorMessage,

        fields  = {},
        fieldsNotToShow = ['Resume', 'Letter', 'Employments'],

        columns = {},
        columnsNotToShow = ['Resume Captured', 'Resume Text', 'Recruiter Name', 'Recruiter Email', 
                            'Created', 'Created', 'Notes', 'Modified'],

        $container,
        $navigation_wrapper,
        fileID;

    /*
     *
     */

    function buildNavigation() {
        $navigation_wrapper = jQuery(Mustache.render(
            Luceo.Templates.get('modules/talentgather/navigation'),
            {
                'translations': translations
            }
        ));

        $container.prepend($navigation_wrapper);

        jQuery.each(steps, function(iStep, step) {
            step.panel = jQuery('.step-' + iStep, $container).hide();
            step.tab = jQuery('li.'+iStep, $navigation_wrapper);
        });
    }

    function updateNavigation(newStep) {
        currentStep = newStep;

        jQuery.each(steps, function(iStep, step) {
            if(iStep != newStep) {
                step.tab.removeClass('selected');
                step.panel.fadeOut(200);
            } else {
                step.tab.addClass('selected');
                step.panel.fadeIn(200);
            }
        })
        
        /* Scroll to panel */
        jQuery('html, body').animate({
            scrollTop: $container.offset().top - 10
        }, 200);
    }

    /*
     * Building steps
     */


    /* Step 1: Upload */

    function buildStepUpload() {
        updateNavigation('upload');

        var $stepContainer = steps['upload'].panel,
            $stepError     = jQuery('<p class="alert alert-error" />')
                .text(currentStepErrorMessage)
                .css('display', (currentStepErrorMessage == '') ? 'none' : 'block'),
            
            $actionsSubmitFile      = jQuery('#oUpload_upl').hide(),
            $actionSubmitStepUpload = jQuery('<button class="btn btn-primary" />')
                .text(translations.upload_action_submit_label)
                .click(function(e) {
                    e.preventDefault();
                    $actionsSubmitFile.trigger('click');
                });

            $actionSubmitStepUpload.insertAfter($actionsSubmitFile);
            $stepContainer.prepend($stepError);
    }

    
    /* Step 2: Mapping */
    
    function buildStepMapping() {
        updateNavigation('mapping');

        /* Retreive step container */
        var $stepContainer = steps['mapping'].panel.html(''),

            data = [];
            
        jQuery.each(columns, function(iColumn, column){
            if (columnsNotToShow.indexOf(iColumn) < 0) {
                /*var $li = jQuery('<li />'),
                    $elementColumns = jQuery('<span />').text(iColumn).addClass('details'),
                    $elementFields  = jQuery('<select />')
                        .addClass('fields')
                        .data('id', iColumn)
                        .append(populateFieldsList(fields, fieldsNotToShow))
                        .val(column);*/
                
                data.push({
                    iColumn: iColumn,
                    column: column
                });
            }
        });


        /* Get template */
        var $tpl = jQuery(Mustache.render(
            Luceo.Templates.get('modules/talentgather/step.mapping'),
            {
                'translations': translations,
                'rows': data
            }
        ));

        $stepContainer.append($tpl);


        /* Elements */
        var $ul   = jQuery('<ul class="mapping-list tg-list" />'),
            selectArray = jQuery('.fields', $tpl),

            $submit = jQuery('#tg-mapping-submit', $tpl);

        /* Populate fields */
        selectArray
            .append(populateFieldsList(fields, fieldsNotToShow));

        /* Auto select */
        jQuery.each(selectArray, function(i, j) {
            var select = jQuery(j);
            select.val(select.data('value'));
        });

        selectArray.chosen({
            allow_single_deselect: true
        });
        


        $submit
            .text(translations.mapping_action_submit_label)
            .click(function(e) {
                e.preventDefault();

                jQuery(this).attr('disabled', 'disabled').html(icons.loading + translations.mapping_action_submit_label_submitted);

                var elements = jQuery('.fields'),
                    data = {};

                jQuery.each(elements, function(iElement, element) {
                    var select = jQuery(element);
                    var selectedOptionText = jQuery(':selected', select).attr('value');
                    if (selectedOptionText !== '') {
                        data[select.data('id')] =  selectedOptionText;
                    }
                });

                /* Send via XHR */
                jQuery.ajax({
                    method: "GET",
                    url: '/' + _psfc + '.php' + '/psframework/remotemethod',
                    data: { 
                        method: 'BaseTalentgatherActions::prepareImportCandidates',
                        args: Base64.encode(JSON.encode({
                            fileName: fileID,
                            mapping: data
                        }))
                    }
                })
                .done(function(result) {
                    try {
                        JSON.parse(result);
                    } catch(e) {
                        errorServer(); 
                        return false;
                    }

                    buildStepPrepare(
                        JSON.parse(result),
                        data
                    );
                })
                .fail(function() {
                    errorServer(); 
                    return false;
                });
            });
    }
    
    /* Step 3: Prepare Import */
    
    function buildStepPrepare(data, mapping) {

        updateNavigation('prepare');
        
        var candidatesSpecs = {},
            selectEditCheckboxArrayDefault = {
                reference: false,
                label: true,
                geo1: false,
                geo2: false
            },

            $stepContainer = steps['prepare'].panel.html('');

            /* Add additionnal data */
            jQuery.each(data['candidates'], function(iCandidate, candidate){
                candidate.index = iCandidate;
            });

            /* Get template */
            var $tpl = jQuery(Mustache.render(
                Luceo.Templates.get('modules/talentgather/step.prepare'),
                {
                    'translations': translations,
                    'candidates': data['candidates']
                }
            ));

            $stepContainer.append($tpl);

            /* Bind elements */
            var checkboxArray = jQuery('.tg-prepare-checkbox', $tpl),
                $checkboxAll = jQuery('#tg-prepare-checkbox-all', $tpl),

                $selectEdit = jQuery('#tg-prepare-select-edit', $tpl),
                $selectEditSubmit = jQuery('#tg-prepare-select-edit-submit', $tpl),

                selectEditCheckboxArray = jQuery('.tg-prepare-select-edit-checkbox', $tpl),
                
                $submit = jQuery('#tg-prepare-submit', $tpl),
                
                selectArray = jQuery('.tg-prepare-select', $tpl),
                $selectAll = jQuery('#tg-prepare-select-all', $tpl);

            /* Pre select */
            jQuery.each(selectEditCheckboxArray, function(i, j) {
                this.checked = selectEditCheckboxArrayDefault[this.value];
            });


            populateRequisitionList(selectArray, data['requisitions'], selectEditCheckboxArray),
            populateRequisitionList($selectAll, data['requisitions'], selectEditCheckboxArray);


            var modal = new RModal(
                document.getElementById('tg-prepare-select-edit-modal'),  // See "Options" below details,
                {
                    focus: true,
                    escapeClose: true,
                }
            );

            window.modal = modal;

            selectEditCheckboxArray.click(function() {
                selectEditCheckboxArray[this.value] = this.checked;
            });

            $selectEdit.click(function(e){
                e.preventDefault();
                
                modal.open();
            });
    
            $selectEditSubmit.click(function(e){
                e.preventDefault();

                populateRequisitionList(selectArray, data['requisitions'], selectEditCheckboxArray),
                populateRequisitionList($selectAll, data['requisitions'], selectEditCheckboxArray);
                modal.close();
            });

            /* Bind select all checkboxes action */
            $checkboxAll.click(function(e) {
                this.indeterminate = false;
                jQuery('.tg-prepare-checkbox').prop('checked', this.checked);
            });
            
            /* Bind individual checkboxes action */
            checkboxArray.click(function(e) {
                var statuses = Luceo.Tools.Checkboxes.getIndeterminate(checkboxArray);
                $checkboxAll
                    .prop('indeterminate', statuses.indeterminate)
                    .prop('checked', statuses.checked);
            });

            /* Bind req selects */
            selectArray
             /*   .chosen({
                    allow_single_deselect: true
                })*/
                .change(function() {
                    if ($selectAll.prop('checked')) {
                        selectArray
                            .val(jQuery(this).val())
                            .trigger("chosen:updated");
                    }
                });

            /* Bind submit action */
            $submit
                .click(function(e) {
                    e.preventDefault();
                    jQuery(this).attr('disabled', 'disabled').html(icons.loading + translations.prepare_action_submit_label_submitted);

                var elements = jQuery('#tg-prepare-listing tbody tr'),
                    data = {};

                    jQuery.each(elements, function(iElement, element) {



                        var $container = jQuery(element),
                            $checkbox = jQuery('.tg-prepare-checkbox', $container),
                            $select = jQuery('.tg-prepare-select', $container),

                            candidateID = $container.data('id');

                        candidatesSpecs[candidateID] = {
                            active:      $checkbox.is(":checked"),
                            requisition: jQuery(':selected', $select).attr('value')
                        }
                    });

                    jQuery.ajax({
                        method: "GET",
                        url: '/' + _psfc + '.php' + '/psframework/remotemethod',
                        data: { 
                            method: 'BaseTalentgatherActions::importCandidates',
                            args: Base64.encode(JSON.encode({
                                fileName: fileID,
                                mapping: mapping,
                                candidates: candidatesSpecs
                            }))
                        }
                    })
                    .done(function(result) {
                        try {
                            JSON.parse(result);
                        } catch(e) {
                            errorServer(); 
                            return false;
                        }

                        buildStepResult(JSON.parse(result));
                    })
                    .fail(function() {
                        errorServer(); 
                        return false;
                    });
                });

    
        
    }

    /* Step 1: Import results */
    function buildStepResult(data) {
        updateNavigation('result');

        /* Empty container */
        var $stepContainer = steps['result'].panel.html(''),

            $successesContainer,
            $successesInfo,

            $failuresContainer,
            $failuresInfo;

        /* Successes */
        $successesInfo      = buildInfoBlock(icons.info + translations.result_successes_title, 'alert-success');
        $successesContainer = jQuery('<ul />').addClass('result-list successes-container');

        jQuery.each(data['successes'], function(iSuccess, success){
            var $li = jQuery('<li />'),
                $a = jQuery('<a />')
                    .attr('href', '/' + _psfc + '.php' +'/candidat/dossier?tab=form&id=' + success.id + '&containerID=vwPCC'),
                $firstname = jQuery('<span class="detail firstname" />').text(success.firstName || ''),
                $lastname  = jQuery('<span class="detail lastname" />').text(success.lastName) || '',
                $email     = jQuery('<span class="detail email" />').text(success.email || '');

                $a.append($firstname, $lastname, $email);
                $li.append($a);

            $successesContainer.append($li);
        });

        /* Failures */
        $failuresInfo      = buildInfoBlock(icons.info + translations.result_failures_title, 'alert-error');
        $failuresContainer = jQuery('<ul />').addClass('result-list failures-container');

        jQuery.each(data['failures'], function(iFailure, failure){
            var $li = jQuery('<li />'),
                $firstname = jQuery('<span class="detail firstname" />').text(failure.firstName || ''),
                $lastname  = jQuery('<span class="detail lastname" />').text(failure.lastName || ''),
                $email     = jQuery('<span class="detail email" />').text(failure.email || '');

                $li.append($firstname, $lastname, $email);
                
            $failuresContainer.append($li);
        });

        if(data['successes'].length == 0) {
            $successesContainer.hide();
            $successesInfo.hide();
        }

        if(data['failures'].length == 0) {
            $failuresContainer.hide();
            $failuresInfo.hide();
        }

        /* Add to DOM */
        $stepContainer.append(
            $successesInfo,
            $successesContainer, 
            $failuresInfo,
            $failuresContainer);

    }

    /* 
     *
     */

    function populateFieldsList(values, valuesToExclude) {
        var data = [];
        data.push(jQuery('<option value="" />'));

        jQuery.each(fields, function(iField, field) {
            
            if (valuesToExclude.indexOf(iField) < 0) {
                var text = field.en || field.fr;
                if (text.length > 50) {
                    text = text.substring(0,50) + '...';
                }

                data.push(jQuery('<option value="' + iField + '">' + text + '</option>'));
            }
        })

        /* Sort elements alphabetically */
        data.sort(function (a, b) {
            return a.text() == b.text() ? 0 : a.text() < b.text() ? -1 : 1;
        });

        return data;
    }

    
    function populateRequisitionList(select, values, editCheckboxArray) {

        var data = [];
            data.push(jQuery('<option selected="selected" value="1">Unsolicited</option>').prop('outerHTML'));

        /* Each requisition */
        jQuery.each(values, function(iValue, value) {
            var array = [],
                shown = 0;
            
            jQuery.each(editCheckboxArray, function(i, j) {
                if (this.checked) {
                    array.push(value[this.value]);
                    shown++;
                }
            });

            if (shown == 0) {
                array.push(value['id']);
                jQuery('.tg-prepare-select-edit-checkbox[value=id').prop('checked', true);
                
            }

            var label = value['id'] + value['label'];
                label = array.join(', ');
            
            var option = jQuery('<option />')
                .prop('value', value['id'])
                .text(label);

            data.push(option.prop('outerHTML'));
        });
        
        /* Sort elements alphabetically */
        data.sort(function (a, b) {
            var A = jQuery(a),
                B = jQuery(b);

            return A.text() == B.text() ? 0 : A.text() < B.text() ? -1 : 1;
        });

        jQuery.each(select, function(a, b) {
            var current = jQuery(b),
                oldValue = current.val() || 1;

            jQuery(b).empty().append(data).val(oldValue);
        });

    }

    function buildInfoBlock(_html, _class) {
        return jQuery('<p class="alert" />')
            .addClass(_class || '')
            .html(_html || '');
    }

    function errorServer() {
        alert(translations.app_generic_error)
        window.location.href = '/' + _psfc + '.php' + '/talentgather/mapping';
    }

    View.onLoad = function (){

        /* Store step containers */
        $container   = jQuery('.talentgather-container');
        currentStep  = $container.data('current_step');

        /* Retrieve CSV columns and app fields */
        columns = JSON.parse(Base64.decode($container.data('columns')));
        fields  = JSON.parse(Base64.decode($container.data('fields')));

        /* Retreive translations from data attr */
        translations = JSON.parse(Base64.decode($container.data('translations')));

        /* Retreive error message from data attr */
        currentStepErrorMessage = Base64.decode($container.data('current_step_error_message'));
        
        /* Retreive translations from data attr */
        fileID = $container.data('csv');

        /* Build navbar UI */
        buildNavigation();

        /* Display only current panel */
        steps[currentStep].panel.show();

        /* Init first step from data attr */
        if (currentStep == 'upload') {
            buildStepUpload();
        }

        if (currentStep == 'mapping') {
            buildStepMapping();
        }
    }
})(Luceo.Views.Talentgather = Luceo.Views.Talentgather || {});
