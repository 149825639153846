Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Tools = Luceo.Tools || {};

(function ($) {

    function trackAllWidgets() {
        $("div[data-ga-widget]").each(function (i) {
            var id = $(this).attr('data-ga-widget');
            var title = $(this).attr('data-ga-widget-title');
            Luceo.GaShared.logEvent({
                event: 'widgetImpression',
                widgetName: id,
                widgetDisplayedTitle: title
            })
        });
    }

    function trackClickedWidgets() {
        $("div[data-ga-widget]").each(function (i) {
            $(this).on('click', function () {
                var id = $(this).attr('data-ga-widget');
                var title = $(this).attr('data-ga-widget-title');
                Luceo.GaShared.logEvent({
                    event: 'widgetClick',
                    widgetName: id,
                    widgetDisplayedTitle: title
                })
            });
        });
    }


    Luceo.Views.Tools.trackAllWidgets = trackAllWidgets;
    Luceo.Views.Tools.trackClickedWidgets = trackClickedWidgets;
})(jQuery);
(function (View) {
    View.onLoad = function () {
        Luceo.Views.Tools.trackAllWidgets();
        Luceo.Views.Tools.trackClickedWidgets();
    };
})
(Luceo.Views.Tools.Dashboard = Luceo.Views.Tools.Dashboard || {});
