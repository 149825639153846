var Luceo = window.Luceo || {};
Luceo.Tools = Luceo.Tools || {};

var bsPopover = jQuery.fn.popover.noConflict();
jQuery.fn.bspopover = bsPopover;

Luceo.Tools.Popover = function(params) {
    "use strict";

    /*  @params 
            - element           [selector] should be unique
            - title
            - placement         [top|right|bottom|left + auto], see bootstrap for more info
            - showpin           [true/false] Show pin to keep the popover up

            - text || content || 
              remotecontent
                - HTTPmethod        [Http Method use for the request]
                - controler         [Controller name]
                - *viewId           [View ID]
                - *viewUrl          [View URL]
                - *viewQueryString  [View URL querystring]
                - cache             [Cache result]
                - stripScripts      [Strip JS scripts from result]

    */

    var that = this;
    var elements = {};        

    if (! params.element) {
        console.log('Luceo.Tools.Popover: missing element param');
        return false;
    }

    /* Retrieve jQuery Element */  
    elements.target = jQuery(params.element);
    
    /* Target has to exist */
    if (elements.target.length < 1) {
        console.log('Luceo.Tools.Popover: element not found');
        return false;
    }

    /* Target has to be unique */
    if (elements.target.length > 1) {
        console.log('Luceo.Tools.Popover: requires one unique element');
        return false;
    }

    /* Target has to be unique */
    if (params.remotecontent && ! (
        params.remotecontent.viewId &&
        params.remotecontent.viewUrl &&
        params.remotecontent.viewQueryString)) {
        console.log('Luceo.Tools.Popover: remotecontent requieres 3 mandatory params');
        return false;
    }

    /* Generate unique ID (well not so unique but unique enougth) */
    var id = Math.floor((1 + Math.random()) * 0x10000000000).toString(16);

    /* Default config for Popover */
    var config = {};
    config.title = null;
    config.content = null;
    config.placement = 'auto top';
    config.container = '#wrapper-' + id;
    config.delay = 0;
    config.html = true;

    /* Data collector */
    var data = {};
    data.loadedContent = null;

    /* Load config from params */
    if (params.title) config.title = params.title;
    if (params.placement) config.placement = params.placement;

    if(params.text){
        config.content = params.text
        config.html = false;
    } else if(params.html){
        config.content = params.html;
    } else if(params.remotecontent){
        config.content = 'Loading...';
    }

    /* States */
    var states = {
        created: false,
        element: false, 
        visible: false,
        pin: false,
        showpin: (params.showpin) ? params.showpin : false
    };

    /* Wrap element */
    elements.target.wrap('<div id="wrapper-' + id + '" class="wrapper-popover"></div>', elements.target);
    elements.wrapper = jQuery('#wrapper-' + id);

    /* Set Popover title */
    if (config.title == null || config.title == '') {
        elements.wrapper.addClass('no-title');
    }
    
    /* Bind Mouseenter & Mouseleave */
    elements.wrapper.on('mouseenter', function() {
        states.element = true;

        /* If external content && eternal content is not loaded yet */
        if (params.remotecontent && data.loadedContent == null) {
            
            if(params.remotecontent 
                && params.remotecontent.viewId 
                && params.remotecontent.viewUrl
                && params.remotecontent.viewQueryString){

            }
            elements.target.css('cursor', 'wait');
            loadContent();
        }
        else {
            if (! states.visible && ! states.pin) {
                if (! states.created) {
                    elements.target.bspopover(config);
                    states.created = true;    
                }
                elements.target.bspopover('show');
            }
        }
    });

    elements.wrapper.on('mouseleave', function() {
        if (! states.pin) {
            states.element = false;
            setTimeout(function(){ disappear(); }, 500);
        } 
    });


    /* Bind events on popover */
    elements.target.on('shown.bs.popover', function() {
        states.visible = true;
    });

    elements.target.on('inserted.bs.popover', function() {
        elements.arrow = jQuery('.arrow', elements.wrapper);
        elements.popover = jQuery('.popover', elements.wrapper)
            .addClass('popover-maximize');

        elements.title = jQuery('.popover-title', elements.wrapper);

        if (states.showpin && ! elements.pin){
            var rotation = 'selected';
            elements.popover.append(jQuery('<i class="popover-pin fa fa-map-pin"></i>'));
            elements.pin = jQuery('.popover-pin', elements.wrapper)
                .click(function() {
                    states.pin = ! states.pin;

                    if (states.pin) {
                        jQuery(this).addClass(rotation);
                    } else {
                        jQuery(this).removeClass(rotation);
                    }
                });
        }
    });

    elements.target.on('hidden.bs.popover', function() {
        states.visible = false;
    });

    /* Ajaxload callback */
    this.onAjaxLoaded = function(response) {
        data.loadedContent = response;
        config.content = response;
        if (! states.created) {
            elements.target.bspopover(config);
            states.created = true;    
        }
        elements.target.bspopover('show');  
        elements.target.css('cursor', 'pointer');      
    }
    

    /* Disappear callback */
    function disappear() {
        if (! states.element) {
            elements.target.bspopover('hide');
        }
    }

    /* Prepare Ajaxload */
    function loadContent() {
        var oContents = params.remotecontent;
         
        if (! oContents.HTTPmethod) {
            oContents.HTTPmethod = 'get';
        }

        if (! oContents.controler) {
            oContents.controler = _psfc;
        }

        if (! oContents.cache) {
            oContents.cache = false;
        }

        if (! oContents.stripScripts) {
            oContents.cache = true;
        }

        PsAjax.loadView({
            HTTPmethod: oContents.HTTPmethod,
            controler: oContents.controler,
            id: oContents.viewId,
            url: oContents.viewUrl,
            queryString: oContents.viewQueryString,
            layout: 'windowlayout',
            ajaxCache: oContents.cache
        }, {
            targetObject: that,
            stripScripts: oContents.stripScripts
        });
    }
};

/* Temporary fix conflict Moo Vs BS */
document.addEventListener('DOMContentLoaded', function() {
    Element.prototype.hide = function() { };
});
