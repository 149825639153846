Luceo.Views = Luceo.Views || {};
Luceo.Views.Jobpostings = Luceo.Views.Jobpostings || {};
Luceo.Views.Jobpostings.Confirm = Luceo.Views.Jobpostings.Confirm || {};

(function (Confirm, $) {
    function deleteJobsOnTN() {
        if (confirm(PsLocal.TXT_SECOND_COMFIRM_MESSAGE)) {
            jQuery.ajax({
                url: '/' + window._psfc + '.php/frontgateway/jobs/delete-on-tn',
                method: 'POST',
                dataType: 'json',
                data: JSON.stringify({ userId: jQuery('#wdwdeleteAllJobPostingsbtnOK').attr('data-userid'), jobSource: jQuery('#wdwdeleteAllJobPostingshidJobSource').val() }),
            }).done(function () {
                showAlert(PsLocal.TXT_DELETE_JOBS_TN_PROCESSING);
            }).fail(function() {
                showAlert(PsLocal.TXT_DELETE_JOBS_TN_ERROR);
            });
        }
    }

    Confirm.deleteJobsOnTN = deleteJobsOnTN;
})(Luceo.Views.Jobpostings.Confirm, jQuery);
