Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Candidates = Luceo.Views.Candidates || {};

(function (View) {
    'use strict';

    var $container,
        $labels,
        $inputs,
        $textArea;

    View.onLoad = function () {
        $container = jQuery('.request-consent-template');
        $container.attr('style', 'padding:0 10%');

        $labels = $container.find('label');
        $labels.attr('style', 'position:inherit; text-align:left;');

        $inputs = $container.find('input');
        $inputs.attr('style', 'width:50rem;');

        $textArea = $container.find('textarea');
        $textArea.attr('style', 'max-height:40rem; height:40rem; width:50rem');
    }
})(Luceo.Views.Candidates.RequestConsent = Luceo.Views.Candidates.RequestConsent || {});
