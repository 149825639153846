Luceo = window.Luceo || {};
Luceo.Assessments = Luceo.Assessments || {};
Luceo.Assessments.Popup = Luceo.Assessments.Popup || {};

(function (Popup, $) {

    // shows the assessment popup
    function show(attach, result) {
        PsTip.create(
        'assessment_hover_' + result[0].candidateId, {
            loadView: false,
            html: buildHtml(result)
        }, {
            attach: attach,
            delay: 50,
            cache: false
        }
    );
    }

    function buildHtml(results) {
        return '' +
            '<div style="max-width:700px">' +
            '<div class="tit">Assessment</div>' +
            '<table class="table_liste" width="100%">' +
            '<thead>' +
            '<tr>' +
                // TODO: inject translations
                '<th>Provider</th>' +
                '<th>Requested package(s)</th>' +
                '<th>Result</th>' +
                '<th>Completion date</th>' +
            '</tr>' +
            '</thead>' +
            '<tbody>' +
            results.map(buildResultRow).join('') +
            '</tbody>' +
            '</table>' +
            '</div>';
    }

    function buildResultRow(result) {
        var date = "";
        if (result.completedDate) {
            date = result.completedDate.toLocaleDateString();
        }

        return '' +
            '<tr>' +
                '<td>' + result.providerName + '</td>' +
                '<td>' + result.package + '</td>' +
                '<td><a href="' + result.url + '" target="_blank">' + result.urlLabel + '</a></td>' +
                '<td>' + date + '</td>' +
            '</tr>';
    }

    // exports
    Popup.show = show;

})(Luceo.Assessments.Popup, jQuery);
