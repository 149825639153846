Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};

(function (View) {
    'use strict';

    View.onLoad = function() {
        // Select Element
        var $select = jQuery('select#lstColonnes_left');
        $select.focus();

        // Select wrapper
        var $container = $select.parents('.element-left').css('position', 'static'),

            options = jQuery('select#lstColonnes_left option'),
            $definitions = jQuery('#definitions'),
            jsonDefs = JSON.decode($definitions.attr('data-defs')),
            isSA = $definitions.attr('data-sa') === "true";
        if (jsonDefs) {
            var noDefinition = jsonDefs[0],
                customDef = jsonDefs[1];
        }

        // Record mouse position
        $container.mousemove(function(e) {
            $container.data('mouseX', e.pageX);
            $container.data('mouseY', e.pageY);
        });

        options.each(function(i, j) {

            var $option = jQuery(this),
                $popover,
                definition;

            var type = jQuery('#lstPortee').val(),
                id = $option.val(),
                field_id = parseInt($option.val())+1;

            // If $definitions has children elements
            if(jsonDefs) {
                if(jsonDefs[type+field_id]) {
                    definition = jsonDefs[type+field_id];
                } else {
                    if (isSA) {
                        definition = noDefinition + '<a rel="noopener noreferrer" target="_blank" href="ref/fielddescriptions/edition?id=0&rpt=true&type=' + type + '&new=true&fieldId=' + id + '">  ' + customDef + '</a>';
                    } else {
                        definition = noDefinition;
                    }
                }

                jQuery(this).webuiPopover(
                    {
                        arrow: false,
                        content: definition,
                        delay: {
                            show: 800,
                            hide: 200,
                        },
                        style: 'report-tip',
                        placement: 'bottom',
                        trigger: 'hover',

                        onShow: function() {
                            if (! $popover) {
                                $popover = jQuery('#' + $option.data('plugin_webuiPopover').id);
                            }
                            $popover.css(
                                {
                                    left: $container.data('mouseX'),
                                    top: $container.data('mouseY')
                                }
                            );
                        }
                    }
                );
            }
        });
    }

})(Luceo.Views.Advancedquery = Luceo.Views.Advancedquery || {});
