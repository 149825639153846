(function($, global){

    global.LoadWFAHiringIndicatorWidget = function(cid, containerId, searchParametersObject, gauge, callback) {
        var newSearchParamsObject = jQuery.extend(true, {}, searchParametersObject);
        containerId = '#' + containerId;

        if($(containerId).children().length > 0){
            $(containerId).empty();
        }

        if(!('disableonetautofilters' in newSearchParamsObject))
            newSearchParamsObject["disableonetautofilters"] = "false";

        var testFunc = function(serverPercentile, percentPerTick, data){
            var rotationPercent = serverPercentile * percentPerTick;
            var interp;
            var ease = (data.hiscore  > 50) ? "harder" : "easier";
            var hiExtra = "The Hiring Indicator score is calculated using data from CareerBuilder, a third party aggregator, and EMSI, the last of which utilizes more than 90 Federal and State government agencies.";
            if (serverPercentile < 20) {
                interp = "very difficult."
            } else if (serverPercentile < 40) {
                interp = "difficult."
            } else if (serverPercentile < 50) {
                interp = "somewhat difficult."
            } else if (serverPercentile < 60) {
                interp = "somewhat easy."
            } else if (serverPercentile < 80) {
                interp = "somewhat easy."
            } else if (serverPercentile <= 100) {
                interp = "easy."
            }

            var hiInterpretation =  (100 - serverPercentile) + "%" + " of all other positions and locations are " + ease + ", making recruitment <b>" + interp + "</b>.";
            $("#HILabel, #HILabelImg").attr('title', "Hiring Indicator|The Hiring Indicator measures the level of difficulty to recruit on a scale of 1 to 100, with 1 being most difficult. For your search:<br><br>" + data.keywords + " " + data.location + " has a Hiring Indicator score of <b>" + serverPercentile + "</b>. " + hiInterpretation + "<br><br>" + hiExtra);

            $("#HILabel, #HILabelImg").cluetip({ splitTitle: '|', cluezIndex: '999999', dropShadow: true, dropShadowSteps: 3, arrows: true, cluetipClass: 'cbTitledTip', width: 450, positionBy: 'mouse' });
            $(containerId).find('#widgetRYGGauge').show();
            $(containerId).find('#gaugeLabels').show();
            $(containerId).find("#widgetRYGGaugeWrapper").show();
            $(containerId).find("#gaugeNeedle").rotate({
                angle: 0,
                duration: 2000,
                animateTo: rotationPercent
            });
        }

        var loadingDiv = $("<div/>").addClass('cb-wfa-widget-loading').css({width:'300px',height:'60px',backgroundColor:'white',
            background: 'url(//dpwidgets.careerbuilder.com/assets/ajax_bar_load.gif) no-repeat center center'});
        $(containerId).append(loadingDiv);
        loadingDiv.show();
        var ajaxURL;
        if(gauge && gauge.toLowerCase() == "gauge")
            ajaxURL = '//dpwidgets.careerbuilder.com/hiringindicator/v2?cid=' + cid + '&gauge=' + gauge;
        else
            ajaxURL = '//dpwidgets.careerbuilder.com/hiringindicator/v2?cid=' + cid;
        $.ajax({
            type: 'GET',
            url: ajaxURL,
            data: { wfaSearchParams: newSearchParamsObject },
            dataType: "jsonp",
            success: function (data) {
                $('.cb-wfa-widget-loading').hide();
                $(containerId).hide();

                // This helps with multiple ajax requests sent in a row.
                if($(containerId).children().length > 0){
                    $(containerId).empty();
                }
                $(containerId).append(data.html);
                if(data.reportURL)
                    $(containerId).append('<input type="hidden" id="widgetReportURL" name="widgetReportURL" value="' + data.reportURL + '" />');
                if(gauge && gauge.toLowerCase() == "gauge")
                {
                    var serverPercentile = data.hiscore;
                    var percentPerTick = 1.8;

                    if(window.loadedHI)
                    {
                        testFunc(serverPercentile, percentPerTick, data);
                        $(containerId).show();
                        if(typeof callback == "function"){
                                callback.apply(arguments)
                        }
                    }else
                    {
                        $.getScript( "//dpwidgets.careerbuilder.com/hiringindicator/lib2.js?cid=" + cid ).done(function( script, textStatus ) {
                            testFunc(serverPercentile, percentPerTick, data);
                            window.loadedHI = 1;
                            $(containerId).show();
                            if(typeof callback == "function"){
                                callback.apply(arguments)
                            }
                        });
                    }
                    
                }else
                {
                    var hiSlideDiv = $(containerId).find('.hiring-indicator-slide');
                    if (hiSlideDiv.length > 0) {
                        $.getScript("//dpwidgets.careerbuilder.com/hiringindicator/lib.js?cid=" + cid, function () {
                            var hiScoreDiv = $(containerId).find('.hiring-indicator-score');
                            var scaleChart = new window.charts.HiringIndicatorScale({el: hiSlideDiv, value: hiScoreDiv.text()});

                            // Track Click of View Portal link.
                            if(typeof s_gi !== undefined) {
                                var s = s_gi('cbglobal');
                                s.linkTrackVars = 'None';
                                s.linkTrackEvents = 'None';
                                s.tl(this, 'o', 'SD- Hiring Indicator scale click through');
                            }
                            if(typeof callback == "function"){
                                callback.apply(arguments)
                            }
                        });
                    }
                    $(containerId).show();
                }
                // If we got a legit HI score back, render a new scale.
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $('.cb-wfa-widget-loading').hide();

                // If we don't get html back for whatever reason, make up an error div.
                var errorDiv = $("<div/>").addClass('cb-wfa-widget-error')
                        .css({'width':'300px','height':'60px','backgroundColor':'white','color': 'black',
                            'font-size': '12px','font-weight':'bold','margin':'5px','text-align': 'center', 'border': 'solid 1.5px #dedede'})
                var errorText = $("<p/>")
                        .css({'vertical-align':'middle', 'line-height':'60px'})
                        .text('Error loading Hiring Indicator Widget.');
                errorDiv.append(errorText);
                $(containerId).append(errorDiv);
            }
        });
    };
})(jQuery, window);
