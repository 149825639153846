jQuery(document).ready(function(){

    var bodyClasses = jQuery('body').attr('class');
    var bodyClassesArray = [];

    if(bodyClasses){
        bodyClassesArray = bodyClasses.split(' ');
    }

    jQuery.each(bodyClassesArray, function(i, className){
        if (className.indexOf('js-view') > -1
            || className.indexOf('is-view') > -1) {
            var regex = /is-view-|js-view-/g;
            className = className.replace(regex, '');
            var classNameArray = className.split('-');

            jQuery.each(classNameArray, function(i , j){
                classNameArray[i] = Luceo.String.capitaliseFirstLetter(j);
            });

            var element = Luceo.Views;

            for (var i = 0; i < classNameArray.length; i++) {
                if (element[classNameArray[i]] === undefined) {
                    return;
                }

                element = element[classNameArray[i]];
            };

            if('onLoad' in element){
                element.onLoad();
            }
        }
    });
});
