Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Automation = Luceo.Views.Automation || {};

(function (View) {
    'use strict';
    View.onLoad = function() {
        jQuery('.collapser').each(function () {
            jQuery(this).click(function (e) {
                jQuery(jQuery(this).attr('href')).css('display', 'hidden');
            });
        })
    }
})(Luceo.Views.Automation.Installcareersites = Luceo.Views.Automation.Installcareersites || {});
