/*
tracks the additional Quick-Search Fields that only appear on the bureau.php page
 */

Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};

(function (View) {
    View.onLoad = function () {
        Luceo.Bureau.Quicksearch.searchedFields()
    };
})(Luceo.Views.Bureau = Luceo.Views.Bureau || {});
