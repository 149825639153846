Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Tools = Luceo.Tools || {};

(function (View) {
    'use strict';

    View.onLoad = function () {
        jQuery('#level_items').sortable({
            placeholder: 'ui-state-highlight',
            update: function () {
                ta('SortLevel', jQuery(this).sortable('toArray').toString());
            }
        });
    }
})(Luceo.Views.Tools.ReqApprovalProfile = Luceo.Views.Tools.ReqApprovalProfile || {});
