Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};

(function (View) {
    View.calculatePosition = function (){
        
        var windowHeight        = jQuery(window).height(), 
            $wrapper            = jQuery('.wrapper-signin'), 
            $signinForm         = jQuery('.signin-form'),
            signinFormMarginTop = Math.round((windowHeight-$signinForm.innerHeight())/2);

        $wrapper.css('padding-top', (signinFormMarginTop > 0) ? signinFormMarginTop : 0);
    }

    View.onLoad = function (){
        View.calculatePosition();

        jQuery( window ).resize(function() {
            View.calculatePosition();
        });

        jQuery('#txtLogin').focus();
    }
})(Luceo.Views.Signin = Luceo.Views.Signin || {});
