/*eslint indent: [2, 4]*/
/*global Luceo jQuery*/

Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Language = Luceo.Views.Language || "";
Luceo.Views.Requisitions = Luceo.Views.Requisitions || {};
Luceo.Views.Requisitions.Categories = Luceo.Views.Requisitions.Categories || {};

(function ($) {
    function register() {
        var $location = $('[name$="txtLocation"]');
        var $discreteLocation = $('[name$="txtDiscreteLocation"]');
        var fields = [
            'lstPays',
            'lstRegion',
            'lstDepartementGeo',
            'lstAgence'
        ];

        function getMatches(term) {
            var data = { query: term };
            if (Luceo.Views.Language) {
                data.culture = Luceo.Views.Language;
            }
            return $.ajax({
                url: Luceo.jsProperties.JobLocationEndpoint + '/match',
                data: data,
                dataType: 'jsonp'
            });
        }
        function getSelectedText(name) {
            return $('[name$="' + name + '"] [value!=""]:selected').text();
        }
        function getCurrentListValues() {
            return filter($.map(fields, getSelectedText)).reverse();
        }
        function automatch(queryText) {
            if (!queryText) {
                return;
            }
            if ($.isArray(queryText)) {
                queryText = queryText.join(', ');
            }
            var timeout = setTimeout(function () {
                Luceo.GaShared.logEvent({
                    event: 'joblocation-autofill',
                    isError: true,
                    query: queryText
                });
            }, 5000);
            getMatches(queryText).then(function (res) {
                clearTimeout(timeout);
                if (res.matches.length > 0 && !Luceo.jsProperties.locationNotFill) {
                    setLocationValue(res.matches[0]);
                } else {
                    setLocationValue(null);
                }
                Luceo.GaShared.logEvent({
                    event: 'joblocation-autofill',
                    isError: false,
                    query: queryText,
                    count: res.matches.length
                });
            }, function (err) { });
        }
        function filter(values) {
            return $.grep($.map(values, $.trim), function (val) {
                return !!val;
            });
        }
        function handler(event) {
            var $elem = $(this);
            var values = getCurrentListValues();
            unselectList('#vw1lstOfficeName');
            $location.val('');
            if (Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
                $location.parents('.input').removeClass('focused');
            }
            if (values.length > 2) {
                automatch(values);
            }
        }
        function setLocationValue(match) {
            if (!match) {
                $location.val('');
                $discreteLocation.val('');
                if (Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
                    $location.parents('.input').removeClass('focused');
                }
                return;
            }

            if (Luceo.jsProperties && Luceo.jsProperties.upliftedUI && match.display_text) {
                $location.parents('.input').addClass('focused');
            }

            $location.val(match.display_text).trigger('change');
            $discreteLocation.val(JSON.stringify(match));
        }

        function unselectList(name) {
            $(name).find(":selected").removeAttr("selected");
        }
        if ($location) {
            $location.autocomplete({
                source: function (request, response) {
                    if (request.term.length < 3) {
                        return response([]);
                    }
                    var timeout = setTimeout(function () {
                        response([]);
                        Luceo.GaShared.logEvent({
                            event: 'joblocation-autocomplete',
                            isError: true,
                            query: request.term
                        });
                    }, 5000);
                    getMatches(request.term).done(function (res) {
                        var results = $.map(res.matches, function (match) {
                            return {
                                label: match.display_text,
                                value: match
                            };
                        });
                        clearTimeout(timeout);
                        response(results);
                        Luceo.GaShared.logEvent({
                            event: 'joblocation-autocomplete',
                            isError: false,
                            query: request.term,
                            count: results.length
                        });
                    });
                }
            });
            $location.on('autocompleteselect autocompletefocus', function (e, ui) {
                e.preventDefault();
                setLocationValue(ui.item.value);
            });
            if (!$location.val()) {
                var currentValues = getCurrentListValues();
                if (currentValues.length > 2) {
                    automatch(getCurrentListValues());
                }
            }
            $.each(fields, function (i, field) {
                $(document.body).on('change', '[name$="' + field + '"]', handler);
            });
            //the next line is to reload the posting location on after an autoposback
            // $() is the short hand for $(document).ready() and passing the handler function
            if (Luceo.jsProperties.shouldAutofillPostingLocation) {
                $(handler);
            }

        }

        if (featureFlag && categoryControl) {
            // Because the WebSelector assumes a static list, we need to monkey patch loadValues()
            // to grab a list based on the country value in the location field
            categoryControl.loadValues = function () {
                var country = ($location) ? $location.val().slice(-2).toUpperCase() : 'US';
                if (!categories.hasOwnProperty(country)) {
                    country = 'US';
                }
                var countryCategories = categories[country];

                if (country === categoryControl.loadedCountry) {
                    return;
                }

                // Save the selected categories into hidden input
                categoryControl.exportSelected();
                categoryControl.selected = categoryControl.importSelected();

                // Empty <select> and refill
                categoryControl.html.select.find('option').remove();
                $.each(countryCategories, function (code, label) {
                    var option = $('<option />').val(code).text(label);
                    if ($.inArray(code, categoryControl.selected) > -1) {
                        option.prop('selected', 'selected');
                    }
                    categoryControl.html.select.append(option);
                });

                this.html.select.trigger('chosen:updated');
                categoryControl.loadedCountry = country;
            };

            // This event handler was causing recursive calls to loadValues()
            categoryControl.onChange = null;

            $location.on('change', function () {
                categoryControl.loadValues();
            });

            categoryControl.loadValues();
        }
    }
    Luceo.Views.Requisitions.registerLocation = register;
}(jQuery));
(function (View) {
    View.onLoad = function () {
        Luceo.Views.Requisitions.registerLocation();
    };
})(Luceo.Views.Requisitions.Folder = Luceo.Views.Requisitions.Folder || {});
