Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Candidates = Luceo.Views.Candidates || {};
Luceo.Views.Candidates.Dms = Luceo.Views.Candidates.Dms || {};
(function (View) {
    // iframed document sends its height using postMessage
    if (window.addEventListener) {
        window.addEventListener('message', function(e) {
            try{
                if (typeof e != 'undefined' && e) {
                    var data = JSON.parse(e.data);
                    if (data['docHeight']) {
                        jQuery('#iframeContainer').height(data['docHeight']+100);
                    }

                    if (data['action'] == 'changeDetected') {
                        jQuery('#showPrompt').val(data['data']['changeDetected'] == false ? 'no' : 'yes');
                    }

                    if (data['action'] == 'submit') {
                        jQuery('#showPrompt').val(data['status'] == 'success' ? 'no' : 'yes');
                    }
                }
            }
            catch(e) {
            }
        });
    }
})(Luceo.Views.Candidates.Dms.Portal = Luceo.Views.Candidates.Dms.Portal || {});
