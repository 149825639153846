Luceo = window.Luceo || {};

(function(Url, currentLocation) {
    Url.Query = {};

    /**
     * Gets the value a parameter in the current url query string.
     * @param {string} key
     * @returns False if the key was not found, else the value of the parameter.
     *
     * @link http://css-tricks.com/snippets/javascript/get-url-variables/
     */
    Url.Query.get = function(key) {
        var query = currentLocation.search.substring(1);
        var vars = query.split("&");

        for (var i=0; i<vars.length; i++) {
            var pair = vars[i].split("=");
            if(pair[0] == key) {
                return pair[1];
            }
        }

        return false;
    };
}(Luceo.Url = Luceo.Url || {}, window.location));
