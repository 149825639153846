Luceo = window.Luceo || {};
Luceo.Pagination = function(_element) {
    "use strict";

    var $pagination = _element || null;

    if (! ($pagination && $pagination.data('idprefix'))) {
        console.error('Luceo.Pagination(), Pagination sould be an element.');
        return null;
    }

    var idprefix = $pagination.data('idprefix'),
        id       = $pagination.data('id'),
        isAjax   = $pagination.data('ajax'),

        $links = jQuery('a', $pagination);

    jQuery.each($links, function() {
        jQuery(this).click(function(e) {
            if (isAjax) {
                ta(idprefix, jQuery(this).data('page'), id, 'true');   
            } else {
                ta(idprefix, jQuery(this).data('page'), id);
            }
            return false;
        });
    })
}

jQuery(document).ready(function() {
    jQuery('[data-toggle="luceo-pagination"]').each(function() {
        if (jQuery(this).data('pagination') == undefined){
            var pagination = new Luceo.Pagination(jQuery(this));
            if (pagination) {
                jQuery(this).data('pagination', pagination);
            }
        }
    })
});
