function randomid(){
    var id = '';
    var range = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
        rangeFull = '0123456789';

    id += range.charAt(Math.floor(Math.random() * range.length));
    for (var i=0; i<4; i++) {
        id += rangeFull.charAt(Math.floor(Math.random() * rangeFull.length));
    }

    return id;
}
