Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Candidates = Luceo.Views.Candidates || {};
Luceo.Views.Candidates.List = Luceo.Views.Candidates.List || {};

(function (View, assessmentResults, Assessments, $) {
    'use strict';

    View.onLoad = function () {
        $(".assessment_icon").each(function (i, icon) {
            var json = $(icon).parent().attr("data-action");
            try {
                var data = JSON.parse(json);
            } catch (e) {
                return;
            }

            var results = Assessments.loadResultsForCandidate(data.candidate_id);
            if (!results || results.length == 0) {
                return;
            }

            $(icon).css('display', 'inline');
            $(icon).hover(function () {
                Assessments.Popup.show(icon, results);
            })
        });
    };
})(
    Luceo.Views.Candidates.List = Luceo.Views.Candidates.List || {},
    Luceo.jsProperties.assessmentResults = Luceo.jsProperties.assessmentResults || {},
    Luceo.Assessments = Luceo.Assessments || {},
    jQuery
);
