Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};

(function (View) {
    'use strict';

    var translations,
    uploadLabel,
    downloadLabel,
    currentStep = 'import',
    result,

    $uploadContainer,
    $downloadContainer,
    $container;

    function generateModal() {
        var modal = document.createElement('div');
        modal.tabIndex = -1;
        modal.id = 'show-result';
        modal.setAttribute('role', 'dialog');
        modal.className = 'modal fade';
        modal.setAttribute('style', 'z-index:1100!important');

        var modal_dialog = document.createElement('div');
        modal_dialog.className = 'modal-dialog';
        modal_dialog.setAttribute('role', 'document');
        modal_dialog.setAttribute('style', 'top:30%;width:60%;')

        var modal_content = document.createElement('div');
        modal_content.className = 'modal-content';

        var modal_header = document.createElement('div');
        modal_header.className = 'modal-header';

        var header_button = document.createElement('button');
        header_button.className = 'close';
        header_button.type = 'button';
        header_button.setAttribute('data-dismiss', 'modal');
        header_button.setAttribute('aria-label', 'close');
        var header_span = document.createElement('span');
        header_span.setAttribute('aria-hidden', 'true');
        header_span.innerHTML = '&times;';
        header_button.appendChild(header_span);
        var header_h4 = document.createElement('h4');
        header_h4.innerHTML = 'Message';
        header_h4.id = 'myModalLabel';
        header_h4.setAttribute('style', 'color:darkred;font-weight:600')
        modal_header.appendChild(header_button);
        modal_header.appendChild(header_h4);

        var modal_body = document.createElement('div');
        modal_body.setAttribute('style', 'font-size:16px;');
        modal_body.className = 'modal-body';

        var modal_footer = document.createElement('div');
        modal_footer.className = 'modal-footer';
        var footer_close = document.createElement('button');
        footer_close.className = 'btn btn-primary';
        footer_close.type = 'button';
        footer_close.id = 'close-msg';
        footer_close.setAttribute('data-dismiss', 'modal');
        footer_close.innerHTML = 'Close';
        // don't need to show save. For further use, may need to show something to list.
        // var footer_save = document.createElement('button');
        // footer_save.className = 'btn btn-primary';
        // footer_save.type = 'button';
        // footer_save.setAttribute('data-dismiss', 'modal');
        // footer_save.innerHTML = 'Save';
        modal_footer.appendChild(footer_close);
        // modal_footer.appendChild(footer_save);

        modal_content.appendChild(modal_header);
        modal_content.appendChild(modal_body);
        modal_content.appendChild(modal_footer);
        modal_dialog.appendChild(modal_content);

        modal.appendChild(modal_dialog);
        document.body.appendChild(modal);
    }

    View.onLoad = function (){

        /* Store step containers */
        $container   = jQuery('.uploadcontacts-container');

        $uploadContainer = jQuery('#upload-label');
        $downloadContainer = jQuery('#download');

        jQuery('.webupl').attr('style','display:inline-grid; margin:0 0 25px 0;');

        /* Retreive translations from data attr */
        translations = JSON.parse(Base64.decode($container.data('translations')));
        currentStep = $container.data('current_step');
        uploadLabel = JSON.parse(Base64.decode($uploadContainer.data('label')));
        downloadLabel = JSON.parse(Base64.decode($downloadContainer.data('label')));
        // result = $container.data('result');

        var $actionsSubmitFile      = jQuery('#oUpload_upl'),
            $actionSubmitStepUpload = jQuery('<button class="btn btn-primary" />')
                .text(translations.upload_action_submit_label)
                .click(function(e) {
                    e.preventDefault();
                    $actionsSubmitFile.trigger('click');
                });

            $actionsSubmitFile = jQuery('#oUpload_upl').attr('accept', ".csv").attr('style', 'display:none');

            $actionSubmitStepUpload.insertAfter($actionsSubmitFile);

            $uploadContainer.text(uploadLabel);
            $downloadContainer.text(downloadLabel);

        generateModal();

        if (currentStep == 'failed') {
            jQuery('.modal-body').append(translations.upload_contacts_failed_label);
            jQuery('#myModalLabel').attr('style', 'color:darkred;');
            jQuery('#myModalLabel').html(currentStep.toUpperCase() + '!');
            jQuery('#show-result').modal();
        }  else if (currentStep == 'invalidformat') {
            jQuery('.modal-body').append(translations.upload_contacts_invalid_utf8_label);
            jQuery('#myModalLabel').attr('style', 'color:darkred;');
            jQuery('#myModalLabel').html(translations.upload_contacts_invalid_format_label);
            jQuery('#show-result').modal();
        } else if (currentStep == 'success') {
            jQuery('#myModalLabel').html(currentStep.toUpperCase() + '!');
            jQuery('#myModalLabel').attr('style', 'color:green');
            jQuery('.modal-body').append(translations.upload_contacts_success_label);
            jQuery('#show-result').modal();
        }

    }
})(Luceo.Views.Uploadcontacts = Luceo.Views.Uploadcontacts || {});
