Luceo = window.Luceo || {};
Luceo.Assessments = Luceo.Assessments || {};
Luceo.jsProperties.assessmentResults = Luceo.jsProperties.assessmentResults || {},

(function (Assessments, assessmentResults) {

    function loadResultsForCandidate(candidateId) {
        return loadResults().filter(function(r) {
            return r.candidateId == candidateId;
        });
    }

    function loadResults() {
        // for now assessment results are read from a js property
        return assessmentResults.map(function(r) {
            if (r.completedDate && r.completedDate.date) {
                r.completedDate = Date.parse(r.completedDate.date);
            }
            if (r.createdDate && r.createdDate.date) {
                r.createdDate = Date.parse(r.createdDate.date);
            }
            return r;
        });
    }

    // exports
    Assessments.loadResults = loadResults;
    Assessments.loadResultsForCandidate = loadResultsForCandidate;

})(
    Luceo.Assessments,
    Luceo.jsProperties.assessmentResults
);
