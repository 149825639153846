Luceo = window.Luceo || {};
Luceo.Components = Luceo.Components || {};

Luceo.Components.Supplydemand = function($element) {
    "use strict";

    if (! $element) return null;

    var data = $element.data('data');

    if (! data.api) {
        $element.text('Component could not be loaded');
        return null;
    }
    
    var $template = jQuery(Mustache.render(
        Luceo.Templates.get('components/supplydemand/main'),
        {
        'data': {
            keywords: data.keywords,
            location: data.location,
            api: {
                hiring_difficulty_indicator: data.api.hiring_difficulty_indicator,
                hiring_difficulty_indicator_opposite: 100-data.api.hiring_difficulty_indicator
            }
        },
        'translations': {
                hiring_indicator: 'Hiring Indicator',
                harder_to_recruit: 'Harder to Recruit',
                easier_to_recruit: 'Easier to Recruit',
                link_text: data.translations.link_text
            }
        }
    ));

    var $canvas = jQuery('canvas', $template),
        $details = jQuery('.details', $template).webuiPopover();

    $element.prepend($template);

    var opts = {
        angle: 0, // The span of the gauge arc
        lineWidth: 0.25, // The line thickness
        radiusScale: 1, // Relative radius
        pointer: {
            length: 0.40, // // Relative to gauge radius
            strokeWidth: 0.035, // The thickness
            color: '#000000' // Fill color
        },
        limitMax: false,     // If false, max value increases automatically if value > maxValue
        limitMin: false,     // If true, the min value of the gauge will be fixed
        colorStart: '#236BA2',   // Colors
        colorStop: '#182642',    // just experiment with them
        strokeColor: '#236BA2',  // to see which ones work best for you
        generateGradient: true,
        highDpiSupport: true     // High resolution support
      };
      var gauge = new Gauge($canvas[0]).setOptions(opts); // create sexy gauge!
      gauge.maxValue = 100; // set max gauge value
      gauge.setMinValue(0);  // Prefer setter over gauge.minValue = 0
      gauge.animationSpeed = 32; // set animation speed (32 is default value)
      gauge.set(data.api.hiring_difficulty_indicator); // set actual value
}

jQuery(document).ready(function() {
    jQuery('[data-toggle="luceo-component-supplydemand"]').each(function() {
        var component = new Luceo.Components.Supplydemand(jQuery(this));
        jQuery(this).data('component', component);
    })
});
