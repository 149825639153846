Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Jobpostings = Luceo.Views.Jobpostings || {};

(function (View) {
    var dateFormat, appDateFormat, dtDateDebut, dtDateFin, iDur;

    View.onLoad = function () {

        appDateFormat = window._dtsettings.format || 'm/d/Y';
        dateFormat = View.mapAppDateFormToJsFormat(appDateFormat);

        dtDateDebut = jQuery('#dtDateDebut');
        dtDateFin = jQuery('#dtDateFin');
        iDuree = jQuery('#iDuree');

        // Autocompletion activated only if all 3 fields are there
        if (dtDateDebut.length && dtDateFin.length && iDuree.length) {
            View.registerDateEvents();
        }

        View.calculateJobpostingDates();

        jQuery('#lstPartenaire_t a').attr('target', '_blank');
    };

    /* Date */

    View.registerDateEvents = function () {
        // Trigger event when changed from calendar
        jQuery('#dtDateFin').on('select', View.calculateJobpostingDates);
        jQuery('#dtDateDebut').on('select', View.calculateJobpostingDates);

        // Trigger event when changed from inputs
        dtDateDebut.next().bind('blur', function (e) {
            dtDateDebut.val(jQuery(this).val());
            View.calculateJobpostingDates('dtDateDebut');
        });

        dtDateFin.next().bind('blur', function (e) {
            dtDateFin.val(jQuery(this).val());
            View.calculateJobpostingDates('dtDateFin');
        });

        iDuree.bind('blur', function (e) {
            View.calculateJobpostingDates('iDuree');
        });
    };

    View.calculateJobpostingDates = function (_from) {
        var from = _from || null;
        var emptyFields = View.getEmptyDateFields();

        if (emptyFields.length == 1 && emptyFields[0] !== from) {
            switch (emptyFields[0]) {
                case 'dtDateDebut':
                    View.calculateJobpostingStartDate();
                    break;

                case 'dtDateFin':
                    View.calculateJobpostingEndDate();
                    break;

                case 'iDuree':
                    View.calculateJobpostingLength();
                    break;
            }
        } else if (emptyFields.length == 0 && from !== 'iDuree') {
            View.calculateJobpostingLength();
        } else if (emptyFields.length == 0 && from == 'iDuree') {
            View.calculateJobpostingEndDate();
        }
    };

    View.calculateJobpostingLength = function () {
        var startDate = moment(dtDateDebut.val(), dateFormat);
        var endDate = moment(dtDateFin.val(), dateFormat);

        if (startDate.isValid() && endDate.isValid()) {
            var diff = endDate.diff(startDate, 'days') + 1;
            // Avoid negative value
            (diff < 1) ? iDuree.val('') : iDuree.val(diff);
        }
    };

    View.calculateJobpostingStartDate = function () {
        var endDate = moment(dtDateFin.val(), dateFormat);
        var length = parseInt(iDuree.val());
        var startDate = endDate.subtract('days', parseInt(iDuree.val()) - 1);

        dtDateDebut.val(startDate.format(dateFormat));
        dtDateDebut.next().val(startDate.format(dateFormat));
    };

    View.calculateJobpostingEndDate = function () {

        var startDate = moment(dtDateDebut.val(), dateFormat);
        var length = parseInt(iDuree.val());
        var endDate = startDate.add('days', parseInt(iDuree.val()) - 1);

        dtDateFin.val(endDate.format(dateFormat));
        dtDateFin.next().val(endDate.format(dateFormat));
    };

    // Check which fields are empty
    View.getEmptyDateFields = function () {
        var fields = [];
        if (!moment(dtDateDebut.val(), dateFormat).isValid()) {
            fields.push('dtDateDebut');
        }
        if (!moment(dtDateFin.val(), dateFormat).isValid()) {
            fields.push('dtDateFin');
        }
        if (iDuree.val() == '') {
            fields.push('iDuree');
        }

        return fields;
    };

    View.mapAppDateFormToJsFormat = function (dateFormat) {
        if (dateFormat === 'd/m/Y') {
            return 'DD/MM/YYYY';
        } else if (dateFormat === 'Y/m/d') {
            return 'YYYY/MM/DD';
        } else {
            return 'MM/DD/YYYY';
        }
    };

})(Luceo.Views.Jobpostings.Edition = Luceo.Views.Jobpostings.Edition || {});
