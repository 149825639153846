CB = window.CB || {};
Luceo = window.Luceo || {};
Luceo.jsProperties = window.Luceo.jsProperties || {};
Luceo.jsProperties.sso = jQuery.extend({}, {
    authenticated: false,
    clientId: '',
    enableSso: false,
    disableSync: false,
    atao_3562_patch_oneiam_session_expiration: false,
    rdp_32795_enable_oneiam_js: false,
    issuer: 'https://wwwtest.auth.careerbuilder.com',
    authenticateUri: 'oneiam/authenticate',
    cookieName: 'luceo_oneiam_ss'
}, window.Luceo.jsProperties.sso || {});

(function ($) {
    function cbLogin(reason) {
        $.ajax({
            url: '/single-sign-on/auth-state',
            data: { reason: reason },
            type: 'DELETE'
        }).done(function () {
            window.location.replace('/login');
        });
    }

    if (!Luceo.jsProperties.sso.enableSso || Luceo.jsProperties.sso.disableSync) {
        return;
    }

    var environmentMap = {
        us: 'production',
        dev: 'staging'
    };

    if (!Luceo.jsProperties.sso.rdp_32795_enable_oneiam_js) {
        $(document).ready(function () {
            CBAuth.init({
                clientId: Luceo.jsProperties.sso.clientId,
                scope: 'openid',
                environment: environmentMap[Luceo.jsProperties.sso.environment]
            }).then(function () {
                var status = CBAuth.getSessionStatus();
                if (status !== 'logged_in' && Luceo.jsProperties.sso.authenticated) {
                    if (Luceo.jsProperties.sso.atao_3562_patch_oneiam_session_expiration && status === 'known') {
                        // Because we call oneIAM too often it sometimes wrongly returns "known" instead of "logged_in"
                        // If we ignore this case it still means that we have a valid user + we also have a valid php session
                        // The drawback is that we don't capture single sign out anymore. User has to log out from CBAT manually
                        // That's a temporary fix for the CBTA system until Platform Software fixes their API
                    } else {
                        cbLogin('cb_status_' + status);
                    }
                } else if (status === 'logged_in') {
                    $.post('/single-sign-on/verify-token', { token: CBAuth.getIDToken() }).done(function (data) {
                        if (!data || !data.status || data.status !== 'valid') {
                            cbLogin('session_mismatch');
                        }
                    });
                }
            });
        });
    } else {
        $(document).ready(function () {
            if (!window.location.href.includes('/external/') && !window.location.href.includes('/ofccp/self_identify2')) {
                var oneiam = new Oneiam({
                    issuer: Luceo.jsProperties.sso.issuer,
                    clientId: Luceo.jsProperties.sso.clientId,
                    authenticateUri: Luceo.jsProperties.sso.authenticateUri,
                    cookieName: Luceo.jsProperties.sso.cookieName
                });

                oneiam.session.changed().then(function(sessionChanged) {
                    // sessioniChanged: 'true' means browserSession is set, so it may already use become to log in
                    // 'false' means no browserSession saved in auth.careerbuilder site
                    if (!sessionChanged) {
                        $.ajax({
                            url: '/single-sign-on/oneiam/token',
                            type: 'GET',
                            success: function(data) {
                                oneiamToken = data;
                                // If logged in, and we're not in login process, we should not do authenticate but skip it
                                // Because token info is saved in session already
                                // Also need to verify the existed token
                                if (!jQuery.isEmptyObject(data)) {
                                    $.post('/single-sign-on/verify-token', { token: oneiamToken.id_token }).done(function (data) {
                                        if (!data || !data.status || data.status !== 'valid') {
                                            cbLogin('session_mismatch');
                                        }
                                    });
                                } else {
                                    oneiam.authenticate();
                                }
                            }
                        });
                    } else {
                        // When using become, the silent should be true
                        oneiam.authenticate({ silent: true }).then(function() {
                            var oneiamToken = null;
                            $.ajax({
                                url: '/single-sign-on/oneiam/token',
                                type: 'GET',
                                success: function(data) {
                                    oneiamToken = data;
                                    if (!jQuery.isEmptyObject(data)) {
                                        $.post('/single-sign-on/verify-token', { token: oneiamToken.id_token }).done(function (data) {
                                            if (!data || !data.status || data.status !== 'valid') {
                                                cbLogin('session_mismatch');
                                            }
                                            location.replace('/bo.php/desktop?loginSuccess=true');
                                        });
                                    } else {
                                        cbLogin('token_empty');
                                    }
                                }
                            });
                        });
                    }
                });
            }
        });
    }
}(jQuery));
