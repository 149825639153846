Luceo = window.Luceo || {};
Luceo.Views = Luceo.Views || {};
Luceo.Views.Candidates = Luceo.Views.Candidates || {};

(function (View) {
    'use strict';
    View.onLoad = function () {
        jQuery('.dms_doc').click(function () {
            jQuery('#doc_preview').attr('src', jQuery(this).data('url'));
            jQuery('.sidenav a').removeClass('active');
            jQuery(this).addClass('active');
        });
        jQuery('.sidenav a:first-child').click();
    }
})(Luceo.Views.Candidates.Previewdoc = Luceo.Views.Candidates.Previewdoc || {});
