jQuery('document').ready(function() {
    
    jQuery.each(jQuery('.is-clipboard'), function() {
        var element = jQuery(this).webuiPopover();

        new Clipboard(this).on('success', function(e) {
            element.webuiPopover('show');
            setTimeout(function(){element.webuiPopover('hide');}, 450);
        });

    });

});
